/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { RootComponent } from "./root.component";
import { APP_INITIALIZER, importProvidersFrom } from "@angular/core";
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from "@angular/material/core";
import { FirebaseOptions, initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { provideNgxStripe } from "ngx-stripe";
import { provideHttpClient } from "@angular/common/http";
import { routes } from "./routing/routes";
import { TypeDBPlatformTitleStrategy } from "./routing/title-strategy";
import { PlatformAuthService } from "./service/authentication/platform-auth.service";
import { lastValueFrom } from "rxjs";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { provideRouter, TitleStrategy } from "@angular/router";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from "@angular/material/tooltip";
import { SanityService } from "./service/sanity.service";
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from "@angular/material/checkbox";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faCircle, faCircleCheck, faCircleExclamation, faCircleXmark, faCircleInfo, faCreditCard, faCheck as faCheckSolid } from "@fortawesome/pro-solid-svg-icons";
import {
    faXmark, faChevronLeft, faChevronRight, faCircleDot, faEllipsis, faEllipsisVertical, faFolders, faHeadset,
    faGear, faMessageDots, faUserGroup, faUsers, faFiles, faObjectsColumn, faColumns3, faChevronUp, faChevronDown,
    faArrowUpRightFromSquare, faSquarePlus, faCirclePause, faCirclePlay, faCircleUp, faTrashCanXmark, faPenToSquare,
    faPlus, faEye, faEyeSlash, faCopy, faUser, faSignOut, faArrowsRotate, faPlay, faPause, faEnvelope, faDownload,
    faArrowRight, faCheck, faDash, faHyphen,
} from "@fortawesome/pro-light-svg-icons";
import { faEnvelope as faEnvelopeSharp } from "@fortawesome/sharp-light-svg-icons";
import { faEnvelope as faEnvelopeThin } from "@fortawesome/pro-thin-svg-icons";
import { faMicrosoft, faGithub, faGoogle  } from "@fortawesome/free-brands-svg-icons";
import posthog from "posthog-js";
import Intercom from "@intercom/messenger-js-sdk";

const rippleGlobalConfig: RippleGlobalOptions = {
    disabled: true,
    animation: {
        enterDuration: 0,
        exitDuration: 0,
    },
};

const tooltipGlobalConfig: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 0,
    position: "above",
};

const checkboxGlobalConfig: MatCheckboxDefaultOptions = {
    color: "primary"
};

let firebaseOptions: FirebaseOptions & { tenantId: string } = { tenantId: "" };

// TODO: clean up this method
function appInitializer(platformAuth: PlatformAuthService, library: FaIconLibrary) {
    faIconsInitializer(library);
    return startUserAuthInitializer(platformAuth);
}

function startUserAuthInitializer(service: PlatformAuthService) {
    return () => lastValueFrom(service.startUserAuth()).then((res) => {
        firebaseOptions = { tenantId: res.tenantId, apiKey: res.apiKey, authDomain: res.authDomain };
        posthog.init(
            res.posthogApiKey,
            {
                api_host: "https://typedb.com/ingest",
                ui_host: "https://us.posthog.com",
                person_profiles: "always",
                capture_pageview: false,
                capture_pageleave: true,
            }
        );
        (window as any)["posthog"] = posthog;
    }).catch((err) => {
        console.error(`Error loading user auth configuration: ${err}`);
    });
}

function faIconsInitializer(library: FaIconLibrary) {
    library.addIcons(...[
        faObjectsColumn, faFolders, faUsers, faUserGroup, faGear, faHeadset, faMessageDots, faFiles,
        faCircleDot, faColumns3, faChevronUp, faChevronDown, faArrowUpRightFromSquare, faSquarePlus, faCirclePause,
        faCirclePlay, faTrashCanXmark, faPenToSquare, faEnvelope, faChevronLeft, faChevronRight, faEllipsisVertical,
        faEllipsis, faCircle, faPlus, faXmark, faEye, faEyeSlash, faCopy, faCircleCheck, faCircleExclamation,
        faCircleXmark, faCircleInfo, faCircleUp, faUser, faSignOut, faArrowsRotate, faPlay, faPause, faDownload,
        faCreditCard, faArrowRight, faMicrosoft, faGithub, faGoogle, faCheck, faDash, faHyphen, faCheckSolid,
        faEnvelopeSharp, faEnvelopeThin
    ] as any[]);
}

Intercom({
    app_id: "zof896ic",
    hide_default_launcher: true,
    custom_launcher_selector: "#sidebar_support",
    alignment: "left",
});

bootstrapApplication(RootComponent, {
    providers: [
        provideRouter(routes),
        { provide: TitleStrategy, useClass: TypeDBPlatformTitleStrategy },
        provideHttpClient(),
        provideAnimations(),
        provideNgxStripe(),
        importProvidersFrom(
            provideFirebaseApp(() => initializeApp(firebaseOptions)),
            provideAuth(() => {
                const auth = getAuth();
                auth.tenantId = firebaseOptions.tenantId;
                return auth;
            }),
        ),
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            deps: [PlatformAuthService, FaIconLibrary, SanityService], /* fetch Sanity data eagerly for performance */
            multi: true,
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { width: "480px" } },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "outline" } },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: rippleGlobalConfig },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipGlobalConfig },
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: checkboxGlobalConfig },
    ]
});
