/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: application/protocol/organization-controller.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../protocol/common";
import * as dependency_2 from "./../../protocol/concept";
import * as pb_1 from "google-protobuf";
export class OrganizationCtlProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProto {
        const message = new OrganizationCtlProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProto {
        return OrganizationCtlProto.deserialize(bytes);
    }
}
export class OrganizationCtlProtoReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: OrganizationCtlProtoGetAccessLevelsReq;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: OrganizationCtlProtoCheckOrgIDReq;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: OrganizationCtlProtoCreateOrgReq;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: OrganizationCtlProtoGetOrgReq;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: OrganizationCtlProtoUpdateOrgReq;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: OrganizationCtlProtoGetMemberReq;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: OrganizationCtlProtoListProjectsReq;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: OrganizationCtlProtoListTeamsReq;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: OrganizationCtlProtoListMembersReq;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: OrganizationCtlProtoListInvitationsReq;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: OrganizationCtlProtoListDeploymentsReq;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: OrganizationCtlProtoInviteMemberReq;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: OrganizationCtlProtoResendInvitationReq;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: OrganizationCtlProtoRevokeInvitationReq;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: OrganizationCtlProtoRemoveMemberReq;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: OrganizationCtlProtoSetMemberAccessLevelReq;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: OrganizationCtlProtoGetFreeDeploymentCountReq;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        createOrg?: never;
        getOrg?: never;
        updateOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        inviteMember?: never;
        resendInvitation?: never;
        revokeInvitation?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: OrganizationCtlProtoGetPayableProvidersReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkOrgId" in data && data.checkOrgId != undefined) {
                this.checkOrgId = data.checkOrgId;
            }
            if ("createOrg" in data && data.createOrg != undefined) {
                this.createOrg = data.createOrg;
            }
            if ("getOrg" in data && data.getOrg != undefined) {
                this.getOrg = data.getOrg;
            }
            if ("updateOrg" in data && data.updateOrg != undefined) {
                this.updateOrg = data.updateOrg;
            }
            if ("getMember" in data && data.getMember != undefined) {
                this.getMember = data.getMember;
            }
            if ("listProjects" in data && data.listProjects != undefined) {
                this.listProjects = data.listProjects;
            }
            if ("listTeams" in data && data.listTeams != undefined) {
                this.listTeams = data.listTeams;
            }
            if ("listMembers" in data && data.listMembers != undefined) {
                this.listMembers = data.listMembers;
            }
            if ("listInvitations" in data && data.listInvitations != undefined) {
                this.listInvitations = data.listInvitations;
            }
            if ("listDeployments" in data && data.listDeployments != undefined) {
                this.listDeployments = data.listDeployments;
            }
            if ("inviteMember" in data && data.inviteMember != undefined) {
                this.inviteMember = data.inviteMember;
            }
            if ("resendInvitation" in data && data.resendInvitation != undefined) {
                this.resendInvitation = data.resendInvitation;
            }
            if ("revokeInvitation" in data && data.revokeInvitation != undefined) {
                this.revokeInvitation = data.revokeInvitation;
            }
            if ("removeMember" in data && data.removeMember != undefined) {
                this.removeMember = data.removeMember;
            }
            if ("setMemberAccessLevel" in data && data.setMemberAccessLevel != undefined) {
                this.setMemberAccessLevel = data.setMemberAccessLevel;
            }
            if ("getFreeDeploymentCount" in data && data.getFreeDeploymentCount != undefined) {
                this.getFreeDeploymentCount = data.getFreeDeploymentCount;
            }
            if ("getPayableProviders" in data && data.getPayableProviders != undefined) {
                this.getPayableProviders = data.getPayableProviders;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoGetAccessLevelsReq, 1) as OrganizationCtlProtoGetAccessLevelsReq;
    }
    set getAccessLevels(value: OrganizationCtlProtoGetAccessLevelsReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkOrgId() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoCheckOrgIDReq, 2) as OrganizationCtlProtoCheckOrgIDReq;
    }
    set checkOrgId(value: OrganizationCtlProtoCheckOrgIDReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckOrgId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get createOrg() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoCreateOrgReq, 3) as OrganizationCtlProtoCreateOrgReq;
    }
    set createOrg(value: OrganizationCtlProtoCreateOrgReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasCreateOrg() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getOrg() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoGetOrgReq, 4) as OrganizationCtlProtoGetOrgReq;
    }
    set getOrg(value: OrganizationCtlProtoGetOrgReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetOrg() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get updateOrg() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoUpdateOrgReq, 5) as OrganizationCtlProtoUpdateOrgReq;
    }
    set updateOrg(value: OrganizationCtlProtoUpdateOrgReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasUpdateOrg() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get getMember() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoGetMemberReq, 7) as OrganizationCtlProtoGetMemberReq;
    }
    set getMember(value: OrganizationCtlProtoGetMemberReq) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasGetMember() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listProjects() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoListProjectsReq, 8) as OrganizationCtlProtoListProjectsReq;
    }
    set listProjects(value: OrganizationCtlProtoListProjectsReq) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListProjects() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get listTeams() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoListTeamsReq, 9) as OrganizationCtlProtoListTeamsReq;
    }
    set listTeams(value: OrganizationCtlProtoListTeamsReq) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasListTeams() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get listMembers() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoListMembersReq, 10) as OrganizationCtlProtoListMembersReq;
    }
    set listMembers(value: OrganizationCtlProtoListMembersReq) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasListMembers() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get listInvitations() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoListInvitationsReq, 11) as OrganizationCtlProtoListInvitationsReq;
    }
    set listInvitations(value: OrganizationCtlProtoListInvitationsReq) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasListInvitations() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get listDeployments() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoListDeploymentsReq, 12) as OrganizationCtlProtoListDeploymentsReq;
    }
    set listDeployments(value: OrganizationCtlProtoListDeploymentsReq) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasListDeployments() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get inviteMember() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoInviteMemberReq, 13) as OrganizationCtlProtoInviteMemberReq;
    }
    set inviteMember(value: OrganizationCtlProtoInviteMemberReq) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
    }
    get hasInviteMember() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get resendInvitation() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoResendInvitationReq, 14) as OrganizationCtlProtoResendInvitationReq;
    }
    set resendInvitation(value: OrganizationCtlProtoResendInvitationReq) {
        pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[0], value);
    }
    get hasResendInvitation() {
        return pb_1.Message.getField(this, 14) != null;
    }
    get revokeInvitation() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoRevokeInvitationReq, 15) as OrganizationCtlProtoRevokeInvitationReq;
    }
    set revokeInvitation(value: OrganizationCtlProtoRevokeInvitationReq) {
        pb_1.Message.setOneofWrapperField(this, 15, this.#one_of_decls[0], value);
    }
    get hasRevokeInvitation() {
        return pb_1.Message.getField(this, 15) != null;
    }
    get removeMember() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoRemoveMemberReq, 16) as OrganizationCtlProtoRemoveMemberReq;
    }
    set removeMember(value: OrganizationCtlProtoRemoveMemberReq) {
        pb_1.Message.setOneofWrapperField(this, 16, this.#one_of_decls[0], value);
    }
    get hasRemoveMember() {
        return pb_1.Message.getField(this, 16) != null;
    }
    get setMemberAccessLevel() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoSetMemberAccessLevelReq, 17) as OrganizationCtlProtoSetMemberAccessLevelReq;
    }
    set setMemberAccessLevel(value: OrganizationCtlProtoSetMemberAccessLevelReq) {
        pb_1.Message.setOneofWrapperField(this, 17, this.#one_of_decls[0], value);
    }
    get hasSetMemberAccessLevel() {
        return pb_1.Message.getField(this, 17) != null;
    }
    get getFreeDeploymentCount() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoGetFreeDeploymentCountReq, 18) as OrganizationCtlProtoGetFreeDeploymentCountReq;
    }
    set getFreeDeploymentCount(value: OrganizationCtlProtoGetFreeDeploymentCountReq) {
        pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0], value);
    }
    get hasGetFreeDeploymentCount() {
        return pb_1.Message.getField(this, 18) != null;
    }
    get getPayableProviders() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoGetPayableProvidersReq, 19) as OrganizationCtlProtoGetPayableProvidersReq;
    }
    set getPayableProviders(value: OrganizationCtlProtoGetPayableProvidersReq) {
        pb_1.Message.setOneofWrapperField(this, 19, this.#one_of_decls[0], value);
    }
    get hasGetPayableProviders() {
        return pb_1.Message.getField(this, 19) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkOrgId" | "createOrg" | "getOrg" | "updateOrg" | "getMember" | "listProjects" | "listTeams" | "listMembers" | "listInvitations" | "listDeployments" | "inviteMember" | "resendInvitation" | "revokeInvitation" | "removeMember" | "setMemberAccessLevel" | "getFreeDeploymentCount" | "getPayableProviders";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkOrgId",
            3: "createOrg",
            4: "getOrg",
            5: "updateOrg",
            7: "getMember",
            8: "listProjects",
            9: "listTeams",
            10: "listMembers",
            11: "listInvitations",
            12: "listDeployments",
            13: "inviteMember",
            14: "resendInvitation",
            15: "revokeInvitation",
            16: "removeMember",
            17: "setMemberAccessLevel",
            18: "getFreeDeploymentCount",
            19: "getPayableProviders"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof OrganizationCtlProtoGetAccessLevelsReq.prototype.toObject>;
        checkOrgId?: ReturnType<typeof OrganizationCtlProtoCheckOrgIDReq.prototype.toObject>;
        createOrg?: ReturnType<typeof OrganizationCtlProtoCreateOrgReq.prototype.toObject>;
        getOrg?: ReturnType<typeof OrganizationCtlProtoGetOrgReq.prototype.toObject>;
        updateOrg?: ReturnType<typeof OrganizationCtlProtoUpdateOrgReq.prototype.toObject>;
        getMember?: ReturnType<typeof OrganizationCtlProtoGetMemberReq.prototype.toObject>;
        listProjects?: ReturnType<typeof OrganizationCtlProtoListProjectsReq.prototype.toObject>;
        listTeams?: ReturnType<typeof OrganizationCtlProtoListTeamsReq.prototype.toObject>;
        listMembers?: ReturnType<typeof OrganizationCtlProtoListMembersReq.prototype.toObject>;
        listInvitations?: ReturnType<typeof OrganizationCtlProtoListInvitationsReq.prototype.toObject>;
        listDeployments?: ReturnType<typeof OrganizationCtlProtoListDeploymentsReq.prototype.toObject>;
        inviteMember?: ReturnType<typeof OrganizationCtlProtoInviteMemberReq.prototype.toObject>;
        resendInvitation?: ReturnType<typeof OrganizationCtlProtoResendInvitationReq.prototype.toObject>;
        revokeInvitation?: ReturnType<typeof OrganizationCtlProtoRevokeInvitationReq.prototype.toObject>;
        removeMember?: ReturnType<typeof OrganizationCtlProtoRemoveMemberReq.prototype.toObject>;
        setMemberAccessLevel?: ReturnType<typeof OrganizationCtlProtoSetMemberAccessLevelReq.prototype.toObject>;
        getFreeDeploymentCount?: ReturnType<typeof OrganizationCtlProtoGetFreeDeploymentCountReq.prototype.toObject>;
        getPayableProviders?: ReturnType<typeof OrganizationCtlProtoGetPayableProvidersReq.prototype.toObject>;
    }): OrganizationCtlProtoReq {
        const message = new OrganizationCtlProtoReq({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = OrganizationCtlProtoGetAccessLevelsReq.fromObject(data.getAccessLevels);
        }
        if (data.checkOrgId != null) {
            message.checkOrgId = OrganizationCtlProtoCheckOrgIDReq.fromObject(data.checkOrgId);
        }
        if (data.createOrg != null) {
            message.createOrg = OrganizationCtlProtoCreateOrgReq.fromObject(data.createOrg);
        }
        if (data.getOrg != null) {
            message.getOrg = OrganizationCtlProtoGetOrgReq.fromObject(data.getOrg);
        }
        if (data.updateOrg != null) {
            message.updateOrg = OrganizationCtlProtoUpdateOrgReq.fromObject(data.updateOrg);
        }
        if (data.getMember != null) {
            message.getMember = OrganizationCtlProtoGetMemberReq.fromObject(data.getMember);
        }
        if (data.listProjects != null) {
            message.listProjects = OrganizationCtlProtoListProjectsReq.fromObject(data.listProjects);
        }
        if (data.listTeams != null) {
            message.listTeams = OrganizationCtlProtoListTeamsReq.fromObject(data.listTeams);
        }
        if (data.listMembers != null) {
            message.listMembers = OrganizationCtlProtoListMembersReq.fromObject(data.listMembers);
        }
        if (data.listInvitations != null) {
            message.listInvitations = OrganizationCtlProtoListInvitationsReq.fromObject(data.listInvitations);
        }
        if (data.listDeployments != null) {
            message.listDeployments = OrganizationCtlProtoListDeploymentsReq.fromObject(data.listDeployments);
        }
        if (data.inviteMember != null) {
            message.inviteMember = OrganizationCtlProtoInviteMemberReq.fromObject(data.inviteMember);
        }
        if (data.resendInvitation != null) {
            message.resendInvitation = OrganizationCtlProtoResendInvitationReq.fromObject(data.resendInvitation);
        }
        if (data.revokeInvitation != null) {
            message.revokeInvitation = OrganizationCtlProtoRevokeInvitationReq.fromObject(data.revokeInvitation);
        }
        if (data.removeMember != null) {
            message.removeMember = OrganizationCtlProtoRemoveMemberReq.fromObject(data.removeMember);
        }
        if (data.setMemberAccessLevel != null) {
            message.setMemberAccessLevel = OrganizationCtlProtoSetMemberAccessLevelReq.fromObject(data.setMemberAccessLevel);
        }
        if (data.getFreeDeploymentCount != null) {
            message.getFreeDeploymentCount = OrganizationCtlProtoGetFreeDeploymentCountReq.fromObject(data.getFreeDeploymentCount);
        }
        if (data.getPayableProviders != null) {
            message.getPayableProviders = OrganizationCtlProtoGetPayableProvidersReq.fromObject(data.getPayableProviders);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof OrganizationCtlProtoGetAccessLevelsReq.prototype.toObject>;
            checkOrgId?: ReturnType<typeof OrganizationCtlProtoCheckOrgIDReq.prototype.toObject>;
            createOrg?: ReturnType<typeof OrganizationCtlProtoCreateOrgReq.prototype.toObject>;
            getOrg?: ReturnType<typeof OrganizationCtlProtoGetOrgReq.prototype.toObject>;
            updateOrg?: ReturnType<typeof OrganizationCtlProtoUpdateOrgReq.prototype.toObject>;
            getMember?: ReturnType<typeof OrganizationCtlProtoGetMemberReq.prototype.toObject>;
            listProjects?: ReturnType<typeof OrganizationCtlProtoListProjectsReq.prototype.toObject>;
            listTeams?: ReturnType<typeof OrganizationCtlProtoListTeamsReq.prototype.toObject>;
            listMembers?: ReturnType<typeof OrganizationCtlProtoListMembersReq.prototype.toObject>;
            listInvitations?: ReturnType<typeof OrganizationCtlProtoListInvitationsReq.prototype.toObject>;
            listDeployments?: ReturnType<typeof OrganizationCtlProtoListDeploymentsReq.prototype.toObject>;
            inviteMember?: ReturnType<typeof OrganizationCtlProtoInviteMemberReq.prototype.toObject>;
            resendInvitation?: ReturnType<typeof OrganizationCtlProtoResendInvitationReq.prototype.toObject>;
            revokeInvitation?: ReturnType<typeof OrganizationCtlProtoRevokeInvitationReq.prototype.toObject>;
            removeMember?: ReturnType<typeof OrganizationCtlProtoRemoveMemberReq.prototype.toObject>;
            setMemberAccessLevel?: ReturnType<typeof OrganizationCtlProtoSetMemberAccessLevelReq.prototype.toObject>;
            getFreeDeploymentCount?: ReturnType<typeof OrganizationCtlProtoGetFreeDeploymentCountReq.prototype.toObject>;
            getPayableProviders?: ReturnType<typeof OrganizationCtlProtoGetPayableProvidersReq.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkOrgId != null) {
            data.checkOrgId = this.checkOrgId.toObject();
        }
        if (this.createOrg != null) {
            data.createOrg = this.createOrg.toObject();
        }
        if (this.getOrg != null) {
            data.getOrg = this.getOrg.toObject();
        }
        if (this.updateOrg != null) {
            data.updateOrg = this.updateOrg.toObject();
        }
        if (this.getMember != null) {
            data.getMember = this.getMember.toObject();
        }
        if (this.listProjects != null) {
            data.listProjects = this.listProjects.toObject();
        }
        if (this.listTeams != null) {
            data.listTeams = this.listTeams.toObject();
        }
        if (this.listMembers != null) {
            data.listMembers = this.listMembers.toObject();
        }
        if (this.listInvitations != null) {
            data.listInvitations = this.listInvitations.toObject();
        }
        if (this.listDeployments != null) {
            data.listDeployments = this.listDeployments.toObject();
        }
        if (this.inviteMember != null) {
            data.inviteMember = this.inviteMember.toObject();
        }
        if (this.resendInvitation != null) {
            data.resendInvitation = this.resendInvitation.toObject();
        }
        if (this.revokeInvitation != null) {
            data.revokeInvitation = this.revokeInvitation.toObject();
        }
        if (this.removeMember != null) {
            data.removeMember = this.removeMember.toObject();
        }
        if (this.setMemberAccessLevel != null) {
            data.setMemberAccessLevel = this.setMemberAccessLevel.toObject();
        }
        if (this.getFreeDeploymentCount != null) {
            data.getFreeDeploymentCount = this.getFreeDeploymentCount.toObject();
        }
        if (this.getPayableProviders != null) {
            data.getPayableProviders = this.getPayableProviders.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckOrgId)
            writer.writeMessage(2, this.checkOrgId, () => this.checkOrgId.serialize(writer));
        if (this.hasCreateOrg)
            writer.writeMessage(3, this.createOrg, () => this.createOrg.serialize(writer));
        if (this.hasGetOrg)
            writer.writeMessage(4, this.getOrg, () => this.getOrg.serialize(writer));
        if (this.hasUpdateOrg)
            writer.writeMessage(5, this.updateOrg, () => this.updateOrg.serialize(writer));
        if (this.hasGetMember)
            writer.writeMessage(7, this.getMember, () => this.getMember.serialize(writer));
        if (this.hasListProjects)
            writer.writeMessage(8, this.listProjects, () => this.listProjects.serialize(writer));
        if (this.hasListTeams)
            writer.writeMessage(9, this.listTeams, () => this.listTeams.serialize(writer));
        if (this.hasListMembers)
            writer.writeMessage(10, this.listMembers, () => this.listMembers.serialize(writer));
        if (this.hasListInvitations)
            writer.writeMessage(11, this.listInvitations, () => this.listInvitations.serialize(writer));
        if (this.hasListDeployments)
            writer.writeMessage(12, this.listDeployments, () => this.listDeployments.serialize(writer));
        if (this.hasInviteMember)
            writer.writeMessage(13, this.inviteMember, () => this.inviteMember.serialize(writer));
        if (this.hasResendInvitation)
            writer.writeMessage(14, this.resendInvitation, () => this.resendInvitation.serialize(writer));
        if (this.hasRevokeInvitation)
            writer.writeMessage(15, this.revokeInvitation, () => this.revokeInvitation.serialize(writer));
        if (this.hasRemoveMember)
            writer.writeMessage(16, this.removeMember, () => this.removeMember.serialize(writer));
        if (this.hasSetMemberAccessLevel)
            writer.writeMessage(17, this.setMemberAccessLevel, () => this.setMemberAccessLevel.serialize(writer));
        if (this.hasGetFreeDeploymentCount)
            writer.writeMessage(18, this.getFreeDeploymentCount, () => this.getFreeDeploymentCount.serialize(writer));
        if (this.hasGetPayableProviders)
            writer.writeMessage(19, this.getPayableProviders, () => this.getPayableProviders.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = OrganizationCtlProtoGetAccessLevelsReq.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkOrgId, () => message.checkOrgId = OrganizationCtlProtoCheckOrgIDReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.createOrg, () => message.createOrg = OrganizationCtlProtoCreateOrgReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getOrg, () => message.getOrg = OrganizationCtlProtoGetOrgReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.updateOrg, () => message.updateOrg = OrganizationCtlProtoUpdateOrgReq.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.getMember, () => message.getMember = OrganizationCtlProtoGetMemberReq.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listProjects, () => message.listProjects = OrganizationCtlProtoListProjectsReq.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.listTeams, () => message.listTeams = OrganizationCtlProtoListTeamsReq.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.listMembers, () => message.listMembers = OrganizationCtlProtoListMembersReq.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.listInvitations, () => message.listInvitations = OrganizationCtlProtoListInvitationsReq.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.listDeployments, () => message.listDeployments = OrganizationCtlProtoListDeploymentsReq.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.inviteMember, () => message.inviteMember = OrganizationCtlProtoInviteMemberReq.deserialize(reader));
                    break;
                case 14:
                    reader.readMessage(message.resendInvitation, () => message.resendInvitation = OrganizationCtlProtoResendInvitationReq.deserialize(reader));
                    break;
                case 15:
                    reader.readMessage(message.revokeInvitation, () => message.revokeInvitation = OrganizationCtlProtoRevokeInvitationReq.deserialize(reader));
                    break;
                case 16:
                    reader.readMessage(message.removeMember, () => message.removeMember = OrganizationCtlProtoRemoveMemberReq.deserialize(reader));
                    break;
                case 17:
                    reader.readMessage(message.setMemberAccessLevel, () => message.setMemberAccessLevel = OrganizationCtlProtoSetMemberAccessLevelReq.deserialize(reader));
                    break;
                case 18:
                    reader.readMessage(message.getFreeDeploymentCount, () => message.getFreeDeploymentCount = OrganizationCtlProtoGetFreeDeploymentCountReq.deserialize(reader));
                    break;
                case 19:
                    reader.readMessage(message.getPayableProviders, () => message.getPayableProviders = OrganizationCtlProtoGetPayableProvidersReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoReq {
        return OrganizationCtlProtoReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 4, 7, 8, 9, 10, 11, 12, 17, 18]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: OrganizationCtlProtoGetAccessLevelsRes;
        checkOrgId?: never;
        getOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: OrganizationCtlProtoCheckOrgIDRes;
        getOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        getOrg?: OrganizationCtlProtoGetOrgRes;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        getOrg?: never;
        getMember?: OrganizationCtlProtoGetMemberRes;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        getOrg?: never;
        getMember?: never;
        listProjects?: OrganizationCtlProtoListProjectsRes;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        getOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: OrganizationCtlProtoListTeamsRes;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        getOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: OrganizationCtlProtoListMembersRes;
        listInvitations?: never;
        listDeployments?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        getOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: OrganizationCtlProtoListInvitationsRes;
        listDeployments?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        getOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: OrganizationCtlProtoListDeploymentsRes;
        getFreeDeploymentCount?: never;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        getOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        getFreeDeploymentCount?: OrganizationCtlProtoGetFreeDeploymentCountRes;
        getPayableProviders?: never;
    } | {
        getAccessLevels?: never;
        checkOrgId?: never;
        getOrg?: never;
        getMember?: never;
        listProjects?: never;
        listTeams?: never;
        listMembers?: never;
        listInvitations?: never;
        listDeployments?: never;
        getFreeDeploymentCount?: never;
        getPayableProviders?: OrganizationCtlProtoGetPayableProvidersRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkOrgId" in data && data.checkOrgId != undefined) {
                this.checkOrgId = data.checkOrgId;
            }
            if ("getOrg" in data && data.getOrg != undefined) {
                this.getOrg = data.getOrg;
            }
            if ("getMember" in data && data.getMember != undefined) {
                this.getMember = data.getMember;
            }
            if ("listProjects" in data && data.listProjects != undefined) {
                this.listProjects = data.listProjects;
            }
            if ("listTeams" in data && data.listTeams != undefined) {
                this.listTeams = data.listTeams;
            }
            if ("listMembers" in data && data.listMembers != undefined) {
                this.listMembers = data.listMembers;
            }
            if ("listInvitations" in data && data.listInvitations != undefined) {
                this.listInvitations = data.listInvitations;
            }
            if ("listDeployments" in data && data.listDeployments != undefined) {
                this.listDeployments = data.listDeployments;
            }
            if ("getFreeDeploymentCount" in data && data.getFreeDeploymentCount != undefined) {
                this.getFreeDeploymentCount = data.getFreeDeploymentCount;
            }
            if ("getPayableProviders" in data && data.getPayableProviders != undefined) {
                this.getPayableProviders = data.getPayableProviders;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoGetAccessLevelsRes, 1) as OrganizationCtlProtoGetAccessLevelsRes;
    }
    set getAccessLevels(value: OrganizationCtlProtoGetAccessLevelsRes) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkOrgId() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoCheckOrgIDRes, 2) as OrganizationCtlProtoCheckOrgIDRes;
    }
    set checkOrgId(value: OrganizationCtlProtoCheckOrgIDRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckOrgId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get getOrg() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoGetOrgRes, 4) as OrganizationCtlProtoGetOrgRes;
    }
    set getOrg(value: OrganizationCtlProtoGetOrgRes) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetOrg() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get getMember() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoGetMemberRes, 7) as OrganizationCtlProtoGetMemberRes;
    }
    set getMember(value: OrganizationCtlProtoGetMemberRes) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasGetMember() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listProjects() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoListProjectsRes, 8) as OrganizationCtlProtoListProjectsRes;
    }
    set listProjects(value: OrganizationCtlProtoListProjectsRes) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListProjects() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get listTeams() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoListTeamsRes, 9) as OrganizationCtlProtoListTeamsRes;
    }
    set listTeams(value: OrganizationCtlProtoListTeamsRes) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasListTeams() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get listMembers() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoListMembersRes, 10) as OrganizationCtlProtoListMembersRes;
    }
    set listMembers(value: OrganizationCtlProtoListMembersRes) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasListMembers() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get listInvitations() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoListInvitationsRes, 11) as OrganizationCtlProtoListInvitationsRes;
    }
    set listInvitations(value: OrganizationCtlProtoListInvitationsRes) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasListInvitations() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get listDeployments() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoListDeploymentsRes, 12) as OrganizationCtlProtoListDeploymentsRes;
    }
    set listDeployments(value: OrganizationCtlProtoListDeploymentsRes) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasListDeployments() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get getFreeDeploymentCount() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoGetFreeDeploymentCountRes, 17) as OrganizationCtlProtoGetFreeDeploymentCountRes;
    }
    set getFreeDeploymentCount(value: OrganizationCtlProtoGetFreeDeploymentCountRes) {
        pb_1.Message.setOneofWrapperField(this, 17, this.#one_of_decls[0], value);
    }
    get hasGetFreeDeploymentCount() {
        return pb_1.Message.getField(this, 17) != null;
    }
    get getPayableProviders() {
        return pb_1.Message.getWrapperField(this, OrganizationCtlProtoGetPayableProvidersRes, 18) as OrganizationCtlProtoGetPayableProvidersRes;
    }
    set getPayableProviders(value: OrganizationCtlProtoGetPayableProvidersRes) {
        pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0], value);
    }
    get hasGetPayableProviders() {
        return pb_1.Message.getField(this, 18) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkOrgId" | "getOrg" | "getMember" | "listProjects" | "listTeams" | "listMembers" | "listInvitations" | "listDeployments" | "getFreeDeploymentCount" | "getPayableProviders";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkOrgId",
            4: "getOrg",
            7: "getMember",
            8: "listProjects",
            9: "listTeams",
            10: "listMembers",
            11: "listInvitations",
            12: "listDeployments",
            17: "getFreeDeploymentCount",
            18: "getPayableProviders"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 4, 7, 8, 9, 10, 11, 12, 17, 18])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof OrganizationCtlProtoGetAccessLevelsRes.prototype.toObject>;
        checkOrgId?: ReturnType<typeof OrganizationCtlProtoCheckOrgIDRes.prototype.toObject>;
        getOrg?: ReturnType<typeof OrganizationCtlProtoGetOrgRes.prototype.toObject>;
        getMember?: ReturnType<typeof OrganizationCtlProtoGetMemberRes.prototype.toObject>;
        listProjects?: ReturnType<typeof OrganizationCtlProtoListProjectsRes.prototype.toObject>;
        listTeams?: ReturnType<typeof OrganizationCtlProtoListTeamsRes.prototype.toObject>;
        listMembers?: ReturnType<typeof OrganizationCtlProtoListMembersRes.prototype.toObject>;
        listInvitations?: ReturnType<typeof OrganizationCtlProtoListInvitationsRes.prototype.toObject>;
        listDeployments?: ReturnType<typeof OrganizationCtlProtoListDeploymentsRes.prototype.toObject>;
        getFreeDeploymentCount?: ReturnType<typeof OrganizationCtlProtoGetFreeDeploymentCountRes.prototype.toObject>;
        getPayableProviders?: ReturnType<typeof OrganizationCtlProtoGetPayableProvidersRes.prototype.toObject>;
    }): OrganizationCtlProtoRes {
        const message = new OrganizationCtlProtoRes({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = OrganizationCtlProtoGetAccessLevelsRes.fromObject(data.getAccessLevels);
        }
        if (data.checkOrgId != null) {
            message.checkOrgId = OrganizationCtlProtoCheckOrgIDRes.fromObject(data.checkOrgId);
        }
        if (data.getOrg != null) {
            message.getOrg = OrganizationCtlProtoGetOrgRes.fromObject(data.getOrg);
        }
        if (data.getMember != null) {
            message.getMember = OrganizationCtlProtoGetMemberRes.fromObject(data.getMember);
        }
        if (data.listProjects != null) {
            message.listProjects = OrganizationCtlProtoListProjectsRes.fromObject(data.listProjects);
        }
        if (data.listTeams != null) {
            message.listTeams = OrganizationCtlProtoListTeamsRes.fromObject(data.listTeams);
        }
        if (data.listMembers != null) {
            message.listMembers = OrganizationCtlProtoListMembersRes.fromObject(data.listMembers);
        }
        if (data.listInvitations != null) {
            message.listInvitations = OrganizationCtlProtoListInvitationsRes.fromObject(data.listInvitations);
        }
        if (data.listDeployments != null) {
            message.listDeployments = OrganizationCtlProtoListDeploymentsRes.fromObject(data.listDeployments);
        }
        if (data.getFreeDeploymentCount != null) {
            message.getFreeDeploymentCount = OrganizationCtlProtoGetFreeDeploymentCountRes.fromObject(data.getFreeDeploymentCount);
        }
        if (data.getPayableProviders != null) {
            message.getPayableProviders = OrganizationCtlProtoGetPayableProvidersRes.fromObject(data.getPayableProviders);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof OrganizationCtlProtoGetAccessLevelsRes.prototype.toObject>;
            checkOrgId?: ReturnType<typeof OrganizationCtlProtoCheckOrgIDRes.prototype.toObject>;
            getOrg?: ReturnType<typeof OrganizationCtlProtoGetOrgRes.prototype.toObject>;
            getMember?: ReturnType<typeof OrganizationCtlProtoGetMemberRes.prototype.toObject>;
            listProjects?: ReturnType<typeof OrganizationCtlProtoListProjectsRes.prototype.toObject>;
            listTeams?: ReturnType<typeof OrganizationCtlProtoListTeamsRes.prototype.toObject>;
            listMembers?: ReturnType<typeof OrganizationCtlProtoListMembersRes.prototype.toObject>;
            listInvitations?: ReturnType<typeof OrganizationCtlProtoListInvitationsRes.prototype.toObject>;
            listDeployments?: ReturnType<typeof OrganizationCtlProtoListDeploymentsRes.prototype.toObject>;
            getFreeDeploymentCount?: ReturnType<typeof OrganizationCtlProtoGetFreeDeploymentCountRes.prototype.toObject>;
            getPayableProviders?: ReturnType<typeof OrganizationCtlProtoGetPayableProvidersRes.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkOrgId != null) {
            data.checkOrgId = this.checkOrgId.toObject();
        }
        if (this.getOrg != null) {
            data.getOrg = this.getOrg.toObject();
        }
        if (this.getMember != null) {
            data.getMember = this.getMember.toObject();
        }
        if (this.listProjects != null) {
            data.listProjects = this.listProjects.toObject();
        }
        if (this.listTeams != null) {
            data.listTeams = this.listTeams.toObject();
        }
        if (this.listMembers != null) {
            data.listMembers = this.listMembers.toObject();
        }
        if (this.listInvitations != null) {
            data.listInvitations = this.listInvitations.toObject();
        }
        if (this.listDeployments != null) {
            data.listDeployments = this.listDeployments.toObject();
        }
        if (this.getFreeDeploymentCount != null) {
            data.getFreeDeploymentCount = this.getFreeDeploymentCount.toObject();
        }
        if (this.getPayableProviders != null) {
            data.getPayableProviders = this.getPayableProviders.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckOrgId)
            writer.writeMessage(2, this.checkOrgId, () => this.checkOrgId.serialize(writer));
        if (this.hasGetOrg)
            writer.writeMessage(4, this.getOrg, () => this.getOrg.serialize(writer));
        if (this.hasGetMember)
            writer.writeMessage(7, this.getMember, () => this.getMember.serialize(writer));
        if (this.hasListProjects)
            writer.writeMessage(8, this.listProjects, () => this.listProjects.serialize(writer));
        if (this.hasListTeams)
            writer.writeMessage(9, this.listTeams, () => this.listTeams.serialize(writer));
        if (this.hasListMembers)
            writer.writeMessage(10, this.listMembers, () => this.listMembers.serialize(writer));
        if (this.hasListInvitations)
            writer.writeMessage(11, this.listInvitations, () => this.listInvitations.serialize(writer));
        if (this.hasListDeployments)
            writer.writeMessage(12, this.listDeployments, () => this.listDeployments.serialize(writer));
        if (this.hasGetFreeDeploymentCount)
            writer.writeMessage(17, this.getFreeDeploymentCount, () => this.getFreeDeploymentCount.serialize(writer));
        if (this.hasGetPayableProviders)
            writer.writeMessage(18, this.getPayableProviders, () => this.getPayableProviders.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = OrganizationCtlProtoGetAccessLevelsRes.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkOrgId, () => message.checkOrgId = OrganizationCtlProtoCheckOrgIDRes.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getOrg, () => message.getOrg = OrganizationCtlProtoGetOrgRes.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.getMember, () => message.getMember = OrganizationCtlProtoGetMemberRes.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listProjects, () => message.listProjects = OrganizationCtlProtoListProjectsRes.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.listTeams, () => message.listTeams = OrganizationCtlProtoListTeamsRes.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.listMembers, () => message.listMembers = OrganizationCtlProtoListMembersRes.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.listInvitations, () => message.listInvitations = OrganizationCtlProtoListInvitationsRes.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.listDeployments, () => message.listDeployments = OrganizationCtlProtoListDeploymentsRes.deserialize(reader));
                    break;
                case 17:
                    reader.readMessage(message.getFreeDeploymentCount, () => message.getFreeDeploymentCount = OrganizationCtlProtoGetFreeDeploymentCountRes.deserialize(reader));
                    break;
                case 18:
                    reader.readMessage(message.getPayableProviders, () => message.getPayableProviders = OrganizationCtlProtoGetPayableProvidersRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoRes {
        return OrganizationCtlProtoRes.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetAccessLevels extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoGetAccessLevels {
        const message = new OrganizationCtlProtoGetAccessLevels({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetAccessLevels {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetAccessLevels();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetAccessLevels {
        return OrganizationCtlProtoGetAccessLevels.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetAccessLevelsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuids?: Uint8Array[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuids" in data && data.orgUuids != undefined) {
                this.orgUuids = data.orgUuids;
            }
        }
    }
    get orgUuids() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as Uint8Array[];
    }
    set orgUuids(value: Uint8Array[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgUuids?: Uint8Array[];
    }): OrganizationCtlProtoGetAccessLevelsReq {
        const message = new OrganizationCtlProtoGetAccessLevelsReq({});
        if (data.orgUuids != null) {
            message.orgUuids = data.orgUuids;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuids?: Uint8Array[];
        } = {};
        if (this.orgUuids != null) {
            data.orgUuids = this.orgUuids;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuids.length)
            writer.writeRepeatedBytes(1, this.orgUuids);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetAccessLevelsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetAccessLevelsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readBytes());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetAccessLevelsReq {
        return OrganizationCtlProtoGetAccessLevelsReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetAccessLevelsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        accessLevels?: dependency_2.AccessLevelProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("accessLevels" in data && data.accessLevels != undefined) {
                this.accessLevels = data.accessLevels;
            }
        }
    }
    get accessLevels() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as dependency_2.AccessLevelProto[];
    }
    set accessLevels(value: dependency_2.AccessLevelProto[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        accessLevels?: dependency_2.AccessLevelProto[];
    }): OrganizationCtlProtoGetAccessLevelsRes {
        const message = new OrganizationCtlProtoGetAccessLevelsRes({});
        if (data.accessLevels != null) {
            message.accessLevels = data.accessLevels;
        }
        return message;
    }
    toObject() {
        const data: {
            accessLevels?: dependency_2.AccessLevelProto[];
        } = {};
        if (this.accessLevels != null) {
            data.accessLevels = this.accessLevels;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.accessLevels.length)
            writer.writePackedEnum(1, this.accessLevels);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetAccessLevelsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetAccessLevelsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.accessLevels = reader.readPackedEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetAccessLevelsRes {
        return OrganizationCtlProtoGetAccessLevelsRes.deserialize(bytes);
    }
}
export class OrganizationCtlProtoCheckOrgID extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoCheckOrgID {
        const message = new OrganizationCtlProtoCheckOrgID({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoCheckOrgID {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoCheckOrgID();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoCheckOrgID {
        return OrganizationCtlProtoCheckOrgID.deserialize(bytes);
    }
}
export class OrganizationCtlProtoCheckOrgIDReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        id?: string;
    }): OrganizationCtlProtoCheckOrgIDReq {
        const message = new OrganizationCtlProtoCheckOrgIDReq({});
        if (data.id != null) {
            message.id = data.id;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoCheckOrgIDReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoCheckOrgIDReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoCheckOrgIDReq {
        return OrganizationCtlProtoCheckOrgIDReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoCheckOrgIDRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        exists?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("exists" in data && data.exists != undefined) {
                this.exists = data.exists;
            }
        }
    }
    get exists() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set exists(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        exists?: boolean;
    }): OrganizationCtlProtoCheckOrgIDRes {
        const message = new OrganizationCtlProtoCheckOrgIDRes({});
        if (data.exists != null) {
            message.exists = data.exists;
        }
        return message;
    }
    toObject() {
        const data: {
            exists?: boolean;
        } = {};
        if (this.exists != null) {
            data.exists = this.exists;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.exists != false)
            writer.writeBool(1, this.exists);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoCheckOrgIDRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoCheckOrgIDRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.exists = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoCheckOrgIDRes {
        return OrganizationCtlProtoCheckOrgIDRes.deserialize(bytes);
    }
}
export class OrganizationCtlProtoCreateOrg extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoCreateOrg {
        const message = new OrganizationCtlProtoCreateOrg({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoCreateOrg {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoCreateOrg();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoCreateOrg {
        return OrganizationCtlProtoCreateOrg.deserialize(bytes);
    }
}
export class OrganizationCtlProtoCreateOrgReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        org?: dependency_2.OrganizationProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("org" in data && data.org != undefined) {
                this.org = data.org;
            }
        }
    }
    get org() {
        return pb_1.Message.getWrapperField(this, dependency_2.OrganizationProto, 2) as dependency_2.OrganizationProto;
    }
    set org(value: dependency_2.OrganizationProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasOrg() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        org?: ReturnType<typeof dependency_2.OrganizationProto.prototype.toObject>;
    }): OrganizationCtlProtoCreateOrgReq {
        const message = new OrganizationCtlProtoCreateOrgReq({});
        if (data.org != null) {
            message.org = dependency_2.OrganizationProto.fromObject(data.org);
        }
        return message;
    }
    toObject() {
        const data: {
            org?: ReturnType<typeof dependency_2.OrganizationProto.prototype.toObject>;
        } = {};
        if (this.org != null) {
            data.org = this.org.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasOrg)
            writer.writeMessage(2, this.org, () => this.org.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoCreateOrgReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoCreateOrgReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 2:
                    reader.readMessage(message.org, () => message.org = dependency_2.OrganizationProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoCreateOrgReq {
        return OrganizationCtlProtoCreateOrgReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetOrg extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoGetOrg {
        const message = new OrganizationCtlProtoGetOrg({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetOrg {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetOrg();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetOrg {
        return OrganizationCtlProtoGetOrg.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetOrgReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgId" in data && data.orgId != undefined) {
                this.orgId = data.orgId;
            }
        }
    }
    get orgId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set orgId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgId?: string;
    }): OrganizationCtlProtoGetOrgReq {
        const message = new OrganizationCtlProtoGetOrgReq({});
        if (data.orgId != null) {
            message.orgId = data.orgId;
        }
        return message;
    }
    toObject() {
        const data: {
            orgId?: string;
        } = {};
        if (this.orgId != null) {
            data.orgId = this.orgId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgId.length)
            writer.writeString(1, this.orgId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetOrgReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetOrgReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetOrgReq {
        return OrganizationCtlProtoGetOrgReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetOrgRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        org?: dependency_2.OrganizationProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("org" in data && data.org != undefined) {
                this.org = data.org;
            }
        }
    }
    get org() {
        return pb_1.Message.getWrapperField(this, dependency_2.OrganizationProto, 1) as dependency_2.OrganizationProto;
    }
    set org(value: dependency_2.OrganizationProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasOrg() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        org?: ReturnType<typeof dependency_2.OrganizationProto.prototype.toObject>;
    }): OrganizationCtlProtoGetOrgRes {
        const message = new OrganizationCtlProtoGetOrgRes({});
        if (data.org != null) {
            message.org = dependency_2.OrganizationProto.fromObject(data.org);
        }
        return message;
    }
    toObject() {
        const data: {
            org?: ReturnType<typeof dependency_2.OrganizationProto.prototype.toObject>;
        } = {};
        if (this.org != null) {
            data.org = this.org.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasOrg)
            writer.writeMessage(1, this.org, () => this.org.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetOrgRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetOrgRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.org, () => message.org = dependency_2.OrganizationProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetOrgRes {
        return OrganizationCtlProtoGetOrgRes.deserialize(bytes);
    }
}
export class OrganizationCtlProtoUpdateOrg extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoUpdateOrg {
        const message = new OrganizationCtlProtoUpdateOrg({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoUpdateOrg {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoUpdateOrg();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoUpdateOrg {
        return OrganizationCtlProtoUpdateOrg.deserialize(bytes);
    }
}
export class OrganizationCtlProtoUpdateOrgReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        org?: dependency_2.OrganizationProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("org" in data && data.org != undefined) {
                this.org = data.org;
            }
        }
    }
    get org() {
        return pb_1.Message.getWrapperField(this, dependency_2.OrganizationProto, 1) as dependency_2.OrganizationProto;
    }
    set org(value: dependency_2.OrganizationProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasOrg() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        org?: ReturnType<typeof dependency_2.OrganizationProto.prototype.toObject>;
    }): OrganizationCtlProtoUpdateOrgReq {
        const message = new OrganizationCtlProtoUpdateOrgReq({});
        if (data.org != null) {
            message.org = dependency_2.OrganizationProto.fromObject(data.org);
        }
        return message;
    }
    toObject() {
        const data: {
            org?: ReturnType<typeof dependency_2.OrganizationProto.prototype.toObject>;
        } = {};
        if (this.org != null) {
            data.org = this.org.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasOrg)
            writer.writeMessage(1, this.org, () => this.org.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoUpdateOrgReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoUpdateOrgReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.org, () => message.org = dependency_2.OrganizationProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoUpdateOrgReq {
        return OrganizationCtlProtoUpdateOrgReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoDeleteOrg extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoDeleteOrg {
        const message = new OrganizationCtlProtoDeleteOrg({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoDeleteOrg {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoDeleteOrg();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoDeleteOrg {
        return OrganizationCtlProtoDeleteOrg.deserialize(bytes);
    }
}
export class OrganizationCtlProtoDeleteOrgReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
    }): OrganizationCtlProtoDeleteOrgReq {
        const message = new OrganizationCtlProtoDeleteOrgReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoDeleteOrgReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoDeleteOrgReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoDeleteOrgReq {
        return OrganizationCtlProtoDeleteOrgReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetMember extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoGetMember {
        const message = new OrganizationCtlProtoGetMember({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetMember {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetMember();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetMember {
        return OrganizationCtlProtoGetMember.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetMemberReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        userId?: string;
        orgUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("userId" in data && data.userId != undefined) {
                this.userId = data.userId;
            }
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
        }
    }
    get userId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set userId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        userId?: string;
        orgUuid?: Uint8Array;
    }): OrganizationCtlProtoGetMemberReq {
        const message = new OrganizationCtlProtoGetMemberReq({});
        if (data.userId != null) {
            message.userId = data.userId;
        }
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            userId?: string;
            orgUuid?: Uint8Array;
        } = {};
        if (this.userId != null) {
            data.userId = this.userId;
        }
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.userId.length)
            writer.writeString(1, this.userId);
        if (this.orgUuid.length)
            writer.writeBytes(2, this.orgUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetMemberReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetMemberReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.userId = reader.readString();
                    break;
                case 2:
                    message.orgUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetMemberReq {
        return OrganizationCtlProtoGetMemberReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetMemberRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        member?: dependency_2.OrgMemberProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("member" in data && data.member != undefined) {
                this.member = data.member;
            }
        }
    }
    get member() {
        return pb_1.Message.getWrapperField(this, dependency_2.OrgMemberProto, 1) as dependency_2.OrgMemberProto;
    }
    set member(value: dependency_2.OrgMemberProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasMember() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        member?: ReturnType<typeof dependency_2.OrgMemberProto.prototype.toObject>;
    }): OrganizationCtlProtoGetMemberRes {
        const message = new OrganizationCtlProtoGetMemberRes({});
        if (data.member != null) {
            message.member = dependency_2.OrgMemberProto.fromObject(data.member);
        }
        return message;
    }
    toObject() {
        const data: {
            member?: ReturnType<typeof dependency_2.OrgMemberProto.prototype.toObject>;
        } = {};
        if (this.member != null) {
            data.member = this.member.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasMember)
            writer.writeMessage(1, this.member, () => this.member.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetMemberRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetMemberRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.member, () => message.member = dependency_2.OrgMemberProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetMemberRes {
        return OrganizationCtlProtoGetMemberRes.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListProjects extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoListProjects {
        const message = new OrganizationCtlProtoListProjects({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListProjects {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListProjects();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListProjects {
        return OrganizationCtlProtoListProjects.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListProjectsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): OrganizationCtlProtoListProjectsReq {
        const message = new OrganizationCtlProtoListProjectsReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListProjectsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListProjectsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListProjectsReq {
        return OrganizationCtlProtoListProjectsReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListProjectsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projects?: dependency_2.ProjectProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projects" in data && data.projects != undefined) {
                this.projects = data.projects;
            }
        }
    }
    get projects() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.ProjectProto, 1) as dependency_2.ProjectProto[];
    }
    set projects(value: dependency_2.ProjectProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        projects?: ReturnType<typeof dependency_2.ProjectProto.prototype.toObject>[];
    }): OrganizationCtlProtoListProjectsRes {
        const message = new OrganizationCtlProtoListProjectsRes({});
        if (data.projects != null) {
            message.projects = data.projects.map(item => dependency_2.ProjectProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            projects?: ReturnType<typeof dependency_2.ProjectProto.prototype.toObject>[];
        } = {};
        if (this.projects != null) {
            data.projects = this.projects.map((item: dependency_2.ProjectProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projects.length)
            writer.writeRepeatedMessage(1, this.projects, (item: dependency_2.ProjectProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListProjectsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListProjectsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.projects, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.ProjectProto.deserialize(reader), dependency_2.ProjectProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListProjectsRes {
        return OrganizationCtlProtoListProjectsRes.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListTeams extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoListTeams {
        const message = new OrganizationCtlProtoListTeams({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListTeams {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListTeams();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListTeams {
        return OrganizationCtlProtoListTeams.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListTeamsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): OrganizationCtlProtoListTeamsReq {
        const message = new OrganizationCtlProtoListTeamsReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListTeamsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListTeamsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListTeamsReq {
        return OrganizationCtlProtoListTeamsReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListTeamsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teams?: dependency_2.TeamProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teams" in data && data.teams != undefined) {
                this.teams = data.teams;
            }
        }
    }
    get teams() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.TeamProto, 1) as dependency_2.TeamProto[];
    }
    set teams(value: dependency_2.TeamProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        teams?: ReturnType<typeof dependency_2.TeamProto.prototype.toObject>[];
    }): OrganizationCtlProtoListTeamsRes {
        const message = new OrganizationCtlProtoListTeamsRes({});
        if (data.teams != null) {
            message.teams = data.teams.map(item => dependency_2.TeamProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            teams?: ReturnType<typeof dependency_2.TeamProto.prototype.toObject>[];
        } = {};
        if (this.teams != null) {
            data.teams = this.teams.map((item: dependency_2.TeamProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teams.length)
            writer.writeRepeatedMessage(1, this.teams, (item: dependency_2.TeamProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListTeamsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListTeamsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.teams, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.TeamProto.deserialize(reader), dependency_2.TeamProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListTeamsRes {
        return OrganizationCtlProtoListTeamsRes.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListMembers extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoListMembers {
        const message = new OrganizationCtlProtoListMembers({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListMembers {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListMembers();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListMembers {
        return OrganizationCtlProtoListMembers.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListMembersReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): OrganizationCtlProtoListMembersReq {
        const message = new OrganizationCtlProtoListMembersReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListMembersReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListMembersReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListMembersReq {
        return OrganizationCtlProtoListMembersReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListMembersRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        members?: dependency_2.OrgMemberProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("members" in data && data.members != undefined) {
                this.members = data.members;
            }
        }
    }
    get members() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.OrgMemberProto, 1) as dependency_2.OrgMemberProto[];
    }
    set members(value: dependency_2.OrgMemberProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        members?: ReturnType<typeof dependency_2.OrgMemberProto.prototype.toObject>[];
    }): OrganizationCtlProtoListMembersRes {
        const message = new OrganizationCtlProtoListMembersRes({});
        if (data.members != null) {
            message.members = data.members.map(item => dependency_2.OrgMemberProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            members?: ReturnType<typeof dependency_2.OrgMemberProto.prototype.toObject>[];
        } = {};
        if (this.members != null) {
            data.members = this.members.map((item: dependency_2.OrgMemberProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.members.length)
            writer.writeRepeatedMessage(1, this.members, (item: dependency_2.OrgMemberProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListMembersRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListMembersRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.members, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.OrgMemberProto.deserialize(reader), dependency_2.OrgMemberProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListMembersRes {
        return OrganizationCtlProtoListMembersRes.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListInvitations extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoListInvitations {
        const message = new OrganizationCtlProtoListInvitations({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListInvitations {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListInvitations();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListInvitations {
        return OrganizationCtlProtoListInvitations.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListInvitationsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): OrganizationCtlProtoListInvitationsReq {
        const message = new OrganizationCtlProtoListInvitationsReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListInvitationsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListInvitationsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListInvitationsReq {
        return OrganizationCtlProtoListInvitationsReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListInvitationsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invitations?: dependency_2.InvitationProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invitations" in data && data.invitations != undefined) {
                this.invitations = data.invitations;
            }
        }
    }
    get invitations() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.InvitationProto, 1) as dependency_2.InvitationProto[];
    }
    set invitations(value: dependency_2.InvitationProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        invitations?: ReturnType<typeof dependency_2.InvitationProto.prototype.toObject>[];
    }): OrganizationCtlProtoListInvitationsRes {
        const message = new OrganizationCtlProtoListInvitationsRes({});
        if (data.invitations != null) {
            message.invitations = data.invitations.map(item => dependency_2.InvitationProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            invitations?: ReturnType<typeof dependency_2.InvitationProto.prototype.toObject>[];
        } = {};
        if (this.invitations != null) {
            data.invitations = this.invitations.map((item: dependency_2.InvitationProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.invitations.length)
            writer.writeRepeatedMessage(1, this.invitations, (item: dependency_2.InvitationProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListInvitationsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListInvitationsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.invitations, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.InvitationProto.deserialize(reader), dependency_2.InvitationProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListInvitationsRes {
        return OrganizationCtlProtoListInvitationsRes.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListDeployments extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoListDeployments {
        const message = new OrganizationCtlProtoListDeployments({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListDeployments {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListDeployments();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListDeployments {
        return OrganizationCtlProtoListDeployments.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListDeploymentsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): OrganizationCtlProtoListDeploymentsReq {
        const message = new OrganizationCtlProtoListDeploymentsReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListDeploymentsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListDeploymentsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListDeploymentsReq {
        return OrganizationCtlProtoListDeploymentsReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoListDeploymentsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deployments?: dependency_2.DeploymentProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deployments" in data && data.deployments != undefined) {
                this.deployments = data.deployments;
            }
        }
    }
    get deployments() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.DeploymentProto, 1) as dependency_2.DeploymentProto[];
    }
    set deployments(value: dependency_2.DeploymentProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        deployments?: ReturnType<typeof dependency_2.DeploymentProto.prototype.toObject>[];
    }): OrganizationCtlProtoListDeploymentsRes {
        const message = new OrganizationCtlProtoListDeploymentsRes({});
        if (data.deployments != null) {
            message.deployments = data.deployments.map(item => dependency_2.DeploymentProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            deployments?: ReturnType<typeof dependency_2.DeploymentProto.prototype.toObject>[];
        } = {};
        if (this.deployments != null) {
            data.deployments = this.deployments.map((item: dependency_2.DeploymentProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deployments.length)
            writer.writeRepeatedMessage(1, this.deployments, (item: dependency_2.DeploymentProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoListDeploymentsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoListDeploymentsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.deployments, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.DeploymentProto.deserialize(reader), dependency_2.DeploymentProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoListDeploymentsRes {
        return OrganizationCtlProtoListDeploymentsRes.deserialize(bytes);
    }
}
export class OrganizationCtlProtoInviteMember extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoInviteMember {
        const message = new OrganizationCtlProtoInviteMember({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoInviteMember {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoInviteMember();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoInviteMember {
        return OrganizationCtlProtoInviteMember.deserialize(bytes);
    }
}
export class OrganizationCtlProtoInviteMemberReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        userEmail?: string;
        orgUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
        teamAccess?: OrganizationCtlProtoInviteMemberTeamAccess[];
        projectAccess?: OrganizationCtlProtoInviteMemberProjectAccess[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4, 5], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("userEmail" in data && data.userEmail != undefined) {
                this.userEmail = data.userEmail;
            }
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
            if ("teamAccess" in data && data.teamAccess != undefined) {
                this.teamAccess = data.teamAccess;
            }
            if ("projectAccess" in data && data.projectAccess != undefined) {
                this.projectAccess = data.projectAccess;
            }
        }
    }
    get userEmail() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set userEmail(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 3, dependency_2.AccessLevelProto.NONE) as dependency_2.AccessLevelProto;
    }
    set accessLevel(value: dependency_2.AccessLevelProto) {
        pb_1.Message.setField(this, 3, value);
    }
    get teamAccess() {
        return pb_1.Message.getRepeatedWrapperField(this, OrganizationCtlProtoInviteMemberTeamAccess, 4) as OrganizationCtlProtoInviteMemberTeamAccess[];
    }
    set teamAccess(value: OrganizationCtlProtoInviteMemberTeamAccess[]) {
        pb_1.Message.setRepeatedWrapperField(this, 4, value);
    }
    get projectAccess() {
        return pb_1.Message.getRepeatedWrapperField(this, OrganizationCtlProtoInviteMemberProjectAccess, 5) as OrganizationCtlProtoInviteMemberProjectAccess[];
    }
    set projectAccess(value: OrganizationCtlProtoInviteMemberProjectAccess[]) {
        pb_1.Message.setRepeatedWrapperField(this, 5, value);
    }
    static fromObject(data: {
        userEmail?: string;
        orgUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
        teamAccess?: ReturnType<typeof OrganizationCtlProtoInviteMemberTeamAccess.prototype.toObject>[];
        projectAccess?: ReturnType<typeof OrganizationCtlProtoInviteMemberProjectAccess.prototype.toObject>[];
    }): OrganizationCtlProtoInviteMemberReq {
        const message = new OrganizationCtlProtoInviteMemberReq({});
        if (data.userEmail != null) {
            message.userEmail = data.userEmail;
        }
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        if (data.teamAccess != null) {
            message.teamAccess = data.teamAccess.map(item => OrganizationCtlProtoInviteMemberTeamAccess.fromObject(item));
        }
        if (data.projectAccess != null) {
            message.projectAccess = data.projectAccess.map(item => OrganizationCtlProtoInviteMemberProjectAccess.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            userEmail?: string;
            orgUuid?: Uint8Array;
            accessLevel?: dependency_2.AccessLevelProto;
            teamAccess?: ReturnType<typeof OrganizationCtlProtoInviteMemberTeamAccess.prototype.toObject>[];
            projectAccess?: ReturnType<typeof OrganizationCtlProtoInviteMemberProjectAccess.prototype.toObject>[];
        } = {};
        if (this.userEmail != null) {
            data.userEmail = this.userEmail;
        }
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        if (this.teamAccess != null) {
            data.teamAccess = this.teamAccess.map((item: OrganizationCtlProtoInviteMemberTeamAccess) => item.toObject());
        }
        if (this.projectAccess != null) {
            data.projectAccess = this.projectAccess.map((item: OrganizationCtlProtoInviteMemberProjectAccess) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.userEmail.length)
            writer.writeString(1, this.userEmail);
        if (this.orgUuid.length)
            writer.writeBytes(2, this.orgUuid);
        if (this.accessLevel != dependency_2.AccessLevelProto.NONE)
            writer.writeEnum(3, this.accessLevel);
        if (this.teamAccess.length)
            writer.writeRepeatedMessage(4, this.teamAccess, (item: OrganizationCtlProtoInviteMemberTeamAccess) => item.serialize(writer));
        if (this.projectAccess.length)
            writer.writeRepeatedMessage(5, this.projectAccess, (item: OrganizationCtlProtoInviteMemberProjectAccess) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoInviteMemberReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoInviteMemberReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.userEmail = reader.readString();
                    break;
                case 2:
                    message.orgUuid = reader.readBytes();
                    break;
                case 3:
                    message.accessLevel = reader.readEnum();
                    break;
                case 4:
                    reader.readMessage(message.teamAccess, () => pb_1.Message.addToRepeatedWrapperField(message, 4, OrganizationCtlProtoInviteMemberTeamAccess.deserialize(reader), OrganizationCtlProtoInviteMemberTeamAccess));
                    break;
                case 5:
                    reader.readMessage(message.projectAccess, () => pb_1.Message.addToRepeatedWrapperField(message, 5, OrganizationCtlProtoInviteMemberProjectAccess.deserialize(reader), OrganizationCtlProtoInviteMemberProjectAccess));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoInviteMemberReq {
        return OrganizationCtlProtoInviteMemberReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoInviteMemberTeamAccess extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, dependency_2.AccessLevelProto.NONE) as dependency_2.AccessLevelProto;
    }
    set accessLevel(value: dependency_2.AccessLevelProto) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }): OrganizationCtlProtoInviteMemberTeamAccess {
        const message = new OrganizationCtlProtoInviteMemberTeamAccess({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            accessLevel?: dependency_2.AccessLevelProto;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.accessLevel != dependency_2.AccessLevelProto.NONE)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoInviteMemberTeamAccess {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoInviteMemberTeamAccess();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoInviteMemberTeamAccess {
        return OrganizationCtlProtoInviteMemberTeamAccess.deserialize(bytes);
    }
}
export class OrganizationCtlProtoInviteMemberProjectAccess extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuid" in data && data.projectUuid != undefined) {
                this.projectUuid = data.projectUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get projectUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set projectUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 2, dependency_2.AccessLevelProto.NONE) as dependency_2.AccessLevelProto;
    }
    set accessLevel(value: dependency_2.AccessLevelProto) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        projectUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }): OrganizationCtlProtoInviteMemberProjectAccess {
        const message = new OrganizationCtlProtoInviteMemberProjectAccess({});
        if (data.projectUuid != null) {
            message.projectUuid = data.projectUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuid?: Uint8Array;
            accessLevel?: dependency_2.AccessLevelProto;
        } = {};
        if (this.projectUuid != null) {
            data.projectUuid = this.projectUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuid.length)
            writer.writeBytes(1, this.projectUuid);
        if (this.accessLevel != dependency_2.AccessLevelProto.NONE)
            writer.writeEnum(2, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoInviteMemberProjectAccess {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoInviteMemberProjectAccess();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.projectUuid = reader.readBytes();
                    break;
                case 2:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoInviteMemberProjectAccess {
        return OrganizationCtlProtoInviteMemberProjectAccess.deserialize(bytes);
    }
}
export class OrganizationCtlProtoResendInvitation extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoResendInvitation {
        const message = new OrganizationCtlProtoResendInvitation({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoResendInvitation {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoResendInvitation();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoResendInvitation {
        return OrganizationCtlProtoResendInvitation.deserialize(bytes);
    }
}
export class OrganizationCtlProtoResendInvitationReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invitationUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invitationUuid" in data && data.invitationUuid != undefined) {
                this.invitationUuid = data.invitationUuid;
            }
        }
    }
    get invitationUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set invitationUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        invitationUuid?: Uint8Array;
    }): OrganizationCtlProtoResendInvitationReq {
        const message = new OrganizationCtlProtoResendInvitationReq({});
        if (data.invitationUuid != null) {
            message.invitationUuid = data.invitationUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            invitationUuid?: Uint8Array;
        } = {};
        if (this.invitationUuid != null) {
            data.invitationUuid = this.invitationUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.invitationUuid.length)
            writer.writeBytes(1, this.invitationUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoResendInvitationReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoResendInvitationReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.invitationUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoResendInvitationReq {
        return OrganizationCtlProtoResendInvitationReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoRevokeInvitation extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoRevokeInvitation {
        const message = new OrganizationCtlProtoRevokeInvitation({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoRevokeInvitation {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoRevokeInvitation();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoRevokeInvitation {
        return OrganizationCtlProtoRevokeInvitation.deserialize(bytes);
    }
}
export class OrganizationCtlProtoRevokeInvitationReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invitationUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invitationUuid" in data && data.invitationUuid != undefined) {
                this.invitationUuid = data.invitationUuid;
            }
        }
    }
    get invitationUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set invitationUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        invitationUuid?: Uint8Array;
    }): OrganizationCtlProtoRevokeInvitationReq {
        const message = new OrganizationCtlProtoRevokeInvitationReq({});
        if (data.invitationUuid != null) {
            message.invitationUuid = data.invitationUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            invitationUuid?: Uint8Array;
        } = {};
        if (this.invitationUuid != null) {
            data.invitationUuid = this.invitationUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.invitationUuid.length)
            writer.writeBytes(1, this.invitationUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoRevokeInvitationReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoRevokeInvitationReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.invitationUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoRevokeInvitationReq {
        return OrganizationCtlProtoRevokeInvitationReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoRemoveMember extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoRemoveMember {
        const message = new OrganizationCtlProtoRemoveMember({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoRemoveMember {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoRemoveMember();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoRemoveMember {
        return OrganizationCtlProtoRemoveMember.deserialize(bytes);
    }
}
export class OrganizationCtlProtoRemoveMemberReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }): OrganizationCtlProtoRemoveMemberReq {
        const message = new OrganizationCtlProtoRemoveMemberReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            userUuid?: Uint8Array;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.userUuid.length)
            writer.writeBytes(2, this.userUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoRemoveMemberReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoRemoveMemberReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.userUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoRemoveMemberReq {
        return OrganizationCtlProtoRemoveMemberReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoSetMemberAccessLevel extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoSetMemberAccessLevel {
        const message = new OrganizationCtlProtoSetMemberAccessLevel({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoSetMemberAccessLevel {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoSetMemberAccessLevel();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoSetMemberAccessLevel {
        return OrganizationCtlProtoSetMemberAccessLevel.deserialize(bytes);
    }
}
export class OrganizationCtlProtoSetMemberAccessLevelReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 3, dependency_2.AccessLevelProto.NONE) as dependency_2.AccessLevelProto;
    }
    set accessLevel(value: dependency_2.AccessLevelProto) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_2.AccessLevelProto;
    }): OrganizationCtlProtoSetMemberAccessLevelReq {
        const message = new OrganizationCtlProtoSetMemberAccessLevelReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            userUuid?: Uint8Array;
            accessLevel?: dependency_2.AccessLevelProto;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.userUuid.length)
            writer.writeBytes(2, this.userUuid);
        if (this.accessLevel != dependency_2.AccessLevelProto.NONE)
            writer.writeEnum(3, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoSetMemberAccessLevelReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoSetMemberAccessLevelReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.userUuid = reader.readBytes();
                    break;
                case 3:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoSetMemberAccessLevelReq {
        return OrganizationCtlProtoSetMemberAccessLevelReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetFreeDeploymentCount extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoGetFreeDeploymentCount {
        const message = new OrganizationCtlProtoGetFreeDeploymentCount({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetFreeDeploymentCount {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetFreeDeploymentCount();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetFreeDeploymentCount {
        return OrganizationCtlProtoGetFreeDeploymentCount.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetFreeDeploymentCountReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
    }): OrganizationCtlProtoGetFreeDeploymentCountReq {
        const message = new OrganizationCtlProtoGetFreeDeploymentCountReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetFreeDeploymentCountReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetFreeDeploymentCountReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetFreeDeploymentCountReq {
        return OrganizationCtlProtoGetFreeDeploymentCountReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetFreeDeploymentCountRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        count?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("count" in data && data.count != undefined) {
                this.count = data.count;
            }
        }
    }
    get count() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set count(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        count?: number;
    }): OrganizationCtlProtoGetFreeDeploymentCountRes {
        const message = new OrganizationCtlProtoGetFreeDeploymentCountRes({});
        if (data.count != null) {
            message.count = data.count;
        }
        return message;
    }
    toObject() {
        const data: {
            count?: number;
        } = {};
        if (this.count != null) {
            data.count = this.count;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.count != 0)
            writer.writeInt32(1, this.count);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetFreeDeploymentCountRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetFreeDeploymentCountRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.count = reader.readInt32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetFreeDeploymentCountRes {
        return OrganizationCtlProtoGetFreeDeploymentCountRes.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetPayableProviders extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): OrganizationCtlProtoGetPayableProviders {
        const message = new OrganizationCtlProtoGetPayableProviders({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetPayableProviders {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetPayableProviders();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetPayableProviders {
        return OrganizationCtlProtoGetPayableProviders.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetPayableProvidersReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
    }): OrganizationCtlProtoGetPayableProvidersReq {
        const message = new OrganizationCtlProtoGetPayableProvidersReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetPayableProvidersReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetPayableProvidersReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetPayableProvidersReq {
        return OrganizationCtlProtoGetPayableProvidersReq.deserialize(bytes);
    }
}
export class OrganizationCtlProtoGetPayableProvidersRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        providers?: dependency_2.ProviderProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("providers" in data && data.providers != undefined) {
                this.providers = data.providers;
            }
        }
    }
    get providers() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.ProviderProto, 1) as dependency_2.ProviderProto[];
    }
    set providers(value: dependency_2.ProviderProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        providers?: ReturnType<typeof dependency_2.ProviderProto.prototype.toObject>[];
    }): OrganizationCtlProtoGetPayableProvidersRes {
        const message = new OrganizationCtlProtoGetPayableProvidersRes({});
        if (data.providers != null) {
            message.providers = data.providers.map(item => dependency_2.ProviderProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            providers?: ReturnType<typeof dependency_2.ProviderProto.prototype.toObject>[];
        } = {};
        if (this.providers != null) {
            data.providers = this.providers.map((item: dependency_2.ProviderProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.providers.length)
            writer.writeRepeatedMessage(1, this.providers, (item: dependency_2.ProviderProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationCtlProtoGetPayableProvidersRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationCtlProtoGetPayableProvidersRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.providers, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.ProviderProto.deserialize(reader), dependency_2.ProviderProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OrganizationCtlProtoGetPayableProvidersRes {
        return OrganizationCtlProtoGetPayableProvidersRes.deserialize(bytes);
    }
}
