/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { environment } from "../environments/environment";
import { AnalyticsService } from "./analytics.service";

import { HttpClient } from "@angular/common/http";

const authToken = environment.env === "production"
    ? `MmY2YjZmNWM1ZWYyOTNhY2ZiZjA6MzQ3NTUwNjZiOGZmYjlmYjdhNTY=`
    : `ZTVjMDIzODRiNTk1ZTUzYzYyNWY6ZDcyYmI5ZmRlMDQyZTY5MWE4MzE=`;

@Injectable({
    providedIn: "root",
})
export class FormService {
    readonly signupFormID = `cloud_signup`;

    constructor(private http: HttpClient, private analytics: AnalyticsService) {
    }

    submit(formId: string, data: { email: string } & Record<string, unknown>): Observable<unknown> {
        const props = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v.toString().trim().length));
        this.analytics.posthog.alias(data.email);
        this.analytics.posthog.set(props);
        this.analytics.cio.identify(data.email, props);
        return this.http.post(`https://typedb.com/forms/${formId}/submit`, { data }, {
            headers: {
                "Authorization": `Basic ${authToken}`,
                "Content-Type": "application/json",
            },
        });
    }
}
