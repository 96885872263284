/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, Input } from '@angular/core';
import { MatTableModule } from "@angular/material/table";
import { ResourceTable, ResourceTableService } from "../../../service/resource-table.service";
import { Credits, CreditsColumn } from "../../../concept/subscription";
import { AsyncPipe, DatePipe } from '@angular/common';
import { CurrencyPipe } from "../currency.pipe";
import { SpinnerComponent } from "typedb-platform-framework";
import { ProviderOptionsScopePipe } from "../provider-options-scope.pipe";
import { DeploymentApi } from "../../../service/deployment/deployment-api.service";
import { ScopeData, ScopeModalComponent } from "../scope-modal/scope-modal.component";
import { MatDialog } from '@angular/material/dialog';
import { CreditsStatusPipe } from "../credits-status.pipe";
import { MatSortModule } from '@angular/material/sort';

@Component({
    selector: 'tp-credits-table',
    templateUrl: './credits-table.component.html',
    standalone: true,
    imports: [AsyncPipe, CurrencyPipe, DatePipe, MatSortModule, MatTableModule, ProviderOptionsScopePipe, SpinnerComponent, CreditsStatusPipe],
})
export class CreditsTableComponent {
    @Input({ required: true }) table!: ResourceTable<Credits, CreditsColumn>;
    readonly deploymentOptions$ = this.deploymentApi.getDeploymentOptions()

    constructor(private deploymentApi: DeploymentApi, private dialog: MatDialog, public tableService: ResourceTableService) {}

    openScopeModal(name: string, scope: ScopeData) {
        this.dialog.open<ScopeModalComponent, { name: string, scope: ScopeData }>(ScopeModalComponent, { data: { name, scope } })
    }
}
