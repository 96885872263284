/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import posthog, { Properties } from "posthog-js";
import { AnalyticsBrowser, ID as cioID, UserTraits } from "@customerio/cdp-analytics-browser";

const GOOGLE_TAG_ID = "G-SNVZCNLJ9R"; // used by Google Analytics
const adConversionIds = {
    signup: "AW-340366363/3eLoCMq7mvUYEJuopqIB",
    createProfile: "AW-340366363/KNEVCLDkqc8ZEJuopqIB",
    submitProjectInfo: "AW-340366363/IEawCLPkqc8ZEJuopqIB",
    joinOrg: "AW-340366363/lQbJCLbkqc8ZEJuopqIB",
    createFreeDeployment: "AW-340366363/cl4HCO6Qr88ZEJuopqIB",
    createPaidDeployment: "AW-340366363/LGo1CPGQr88ZEJuopqIB",
} as const satisfies Record<string, string>;

@Injectable({
    providedIn: "root",
})
export class AnalyticsService {
    private _cio = AnalyticsBrowser.load({
        writeKey: environment.env === "production" ? "13252ebf8d339959b5b9" : "5fed4032be64c59cf336",
        cdnURL: "https://typedb.com/platform",
    }, {
        integrations: {
            "Customer.io Data Pipelines": {
                apiHost: "typedb.com/platform/v1",
                protocol: "https",
            },
        },
    });

    public constructor() {}

    posthog = {
        alias: (newId: string, existingId?: string) => {
            posthog.alias(newId, existingId);
        },
        capturePageView: () => {
            posthog.capture("$pageview");
        },
        reset: () => {
            posthog.reset();
        },
        set: (userPropertiesToSet: Properties) => {
            posthog.setPersonProperties(userPropertiesToSet);
        }
    };

    cio = {
        identify: (id: cioID, traits?: UserTraits) => {
            this._cio.identify(id, traits);
        },
        page: () => {
            this._cio.page();
        },
        reset: () => {
            this._cio.reset();
        },
    };

    google = {
        loadScriptTag: () => {
            if (environment.env !== "production") return;
            const scriptEl = document.createElement("script");
            scriptEl.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_ID}`;
            const scriptEl2 = document.createElement("script");
            scriptEl2.innerHTML = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GOOGLE_TAG_ID}');`;
            document.head.appendChild(scriptEl);
            document.head.appendChild(scriptEl2);
        },
        reportAdConversion: (event: keyof typeof adConversionIds) => {
            if (environment.env !== "production") return;
            window.gtag("event", "conversion", { send_to: adConversionIds[event] });
        },
    };
}
