/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerComponent } from "typedb-platform-framework";
import { PlatformAuthService } from "../../../service/authentication/platform-auth.service";
import { UserApi } from "../../../service/user/user-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { combineLatest, filter, first, map, of, switchMap, tap } from "rxjs";
import { FirebaseError } from "@angular/fire/app";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { RETURN_TO } from "../../../framework/url-params";

@Component({
    selector: "tp-email-verification",
    templateUrl: "email-verification.component.html",
    standalone: true,
    imports: [SpinnerComponent, FontAwesomeModule],
})
export class EmailVerificationComponent {
    state: { status: "pending" } | { status: "emailSent", email: string } = { status: "pending" };

    constructor(
        private router: Router, public identityAuth: IdentityAuthService, private route: ActivatedRoute,
        private snackbar: SnackbarService, private app: ApplicationState, private userApi: UserApi,
        private platformAuth: PlatformAuthService,
    ) {
        combineLatest([this.identityAuth.currentUserStateChanges, this.route.queryParamMap]).pipe(
            first(), map(([user, params]) => [user, params.get("code"), params.get("email")] as const)
        ).subscribe(([user, code, email]) => {
            if (code && email) {
                this.tryVerify(code, decodeURIComponent(email));
            } else if (user) {
                if (!user.emailVerified) {
                    this.state = { status: "emailSent", email: this.identityAuth.currentUser!.email! };
                } else {
                    this.router.navigate(["/"]);
                }
            } else {
                this.router.navigate(["/sign-in"], { queryParams: { [RETURN_TO]: this.router.url } });
            }
        });
    }

    private tryVerify(code: string, email: string) {
        if (!this.identityAuth.currentUser || this.identityAuth.currentUser.email == email) {
            this.verify(code);
        } else {
            this.app.signOut({ callDeauthUser: true, redirectToLoginPage: false }).subscribe(() => this.verify(code));
        }
    }

    private verify(code: string) {
        this.identityAuth.verify(code).pipe(
            switchMap(() => this.app.userAuth$.pipe(first())),
            switchMap((userAuth) => userAuth.status != "logged_out" ? this.updateVerificationStatusAndAuth() : of({})),
        ).subscribe({
            next: () => {
                this.router.navigate(["/"]);
                this.snackbar.success("Your email address is now verified");
            },
            error: (err) => {
                if (err instanceof FirebaseError) {
                    if (err.code === "auth/invalid-action-code") {
                        this.snackbar.errorPersistent("This email verification link is not valid. It may have expired or already been used.");
                    } else {
                        this.snackbar.errorPersistent(err.message);
                    }
                } else {
                    this.snackbar.errorPersistent("An unexpected error occurred while verifying your email address, please try again.");
                }
                this.router.navigate(["/"]);
            }
        });
    }

    updateVerificationStatusAndAuth() {
        return this.identityAuth.getIdToken().pipe(
            switchMap((token) => this.userApi.updateVerificationStatus(token).pipe(map(() => token))),
            switchMap((token) => this.platformAuth.authUserToken(token)),
            tap((userAuth) => { if(userAuth) this.app.userAuth$.next(userAuth) }),
        );
    }

    resendVerificationEmail() {
        // TODO: prevent multiple emails being sent in quick succession
        this.state = { status: "pending" };
        this.userApi.sendVerifyEmail(false).subscribe({
            next: () => {
                const email = this.identityAuth.currentUser!.email!;
                this.state = { status: "emailSent", email };
                this.snackbar.success(`A new link has been sent to ${email}.`);
            },
            error: () => {
                const email = this.identityAuth.currentUser!.email!;
                this.state = { status: "emailSent", email };
            },
        });
    }

    signOut() {
        this.app.signOut({ callDeauthUser: true, redirectToLoginPage: false }).subscribe({
            next: () => {
                this.router.navigate(["/sign-up"]);
            }
        });
    }
}
