/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import {
    ProjectCtlProtoAssignTeamReq,
    ProjectCtlProtoAssignUserReq,
    ProjectCtlProtoCreateProjectReq,
    ProjectCtlProtoDeleteProjectReq,
    ProjectCtlProtoGetAccessLevelsReq,
    ProjectCtlProtoGetProjectReq,
    ProjectCtlProtoListDeploymentsReq,
    ProjectCtlProtoListTeamsReq,
    ProjectCtlProtoListUsersReq,
    ProjectCtlProtoReq, ProjectCtlProtoSetTeamAccessLevelReq,
    ProjectCtlProtoSetUserAccessLevelReq,
    ProjectCtlProtoUnassignTeamReq,
    ProjectCtlProtoUnassignUserReq,
    ProjectCtlProtoUpdateProjectReq
} from "../../../application/protocol/project-controller";
import { ApiListResponse, ApiResponse, responseListOf, responseOf } from "../../concept/api-response";
import { Ok, PartialWithUuid } from "../../concept/base";
import { Parameters, parametersProtoOf } from "../../concept/common";
import { Deployment, partialDeploymentOf } from "../../concept/deployment";
import { AccessLevel, accessLevelOf, accessLevelProtoOf } from "../../concept/iam";
import { partialProjectOf, Project, projectProtoOf } from "../../concept/project";
import { partialProjectTeamAssignmentOf, ProjectTeamAssignment } from "../../concept/team";
import { partialProjectUserAssignmentOf, ProjectUserAssignment } from "../../concept/user";
import { stringToBytes } from "../../util";
import { ApiService } from "../backend/api.service";
import { UnsubListener } from "../backend/platform-api-backend.service";

@Injectable({
    providedIn: "root",
})
export class ProjectApi {
    constructor(private api: ApiService) {
    }

    getAccessLevels(projectUuids: string[]): Observable<AccessLevel[]> {
        return this.api.projectReqRes(new ProjectCtlProtoReq({ getAccessLevels: new ProjectCtlProtoGetAccessLevelsReq({ projectUuids: projectUuids.map(stringToBytes) }) }))
            .pipe(map((res) => res.getAccessLevels.accessLevels.map(accessLevelOf)));
    }

    createProject(props: { project: Partial<Project>, orgUuid: string }): Observable<Ok> {
        return this.api.projectReq(new ProjectCtlProtoReq({
            createProject: new ProjectCtlProtoCreateProjectReq({ project: projectProtoOf(props.project), orgUuid: stringToBytes(props.orgUuid) })
        }));
    }

    getProject(props: { projectId: string, orgUuid: string }, unsub$: UnsubListener): Observable<ApiResponse<Project>> {
        return this.api.projectReqSub(new ProjectCtlProtoReq(
            { getProject: new ProjectCtlProtoGetProjectReq({ projectId: props.projectId, orgUuid: stringToBytes(props.orgUuid) }) }
        ), unsub$).pipe(
            map((res) => responseOf(res.type, partialProjectOf(res.data.getProject.project)))
        );
    }

    updateProject(project: PartialWithUuid<Project>): Observable<Ok> {
        return this.api.projectReq(new ProjectCtlProtoReq({ updateProject: new ProjectCtlProtoUpdateProjectReq({ project: projectProtoOf(project) }) }));
    }

    deleteProject(projectUuid: string): Observable<Ok> {
        return this.api.projectReq(new ProjectCtlProtoReq({ deleteProject: new ProjectCtlProtoDeleteProjectReq({ projectUuid: stringToBytes(projectUuid) }) }));
    }

    listDeployments(projectUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Deployment>> {
        return this.api.projectReqSub(new ProjectCtlProtoReq(
            { listDeployments: new ProjectCtlProtoListDeploymentsReq({ projectUuid: stringToBytes(projectUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listDeployments.deployments.map(partialDeploymentOf))));
    }

    listUsers(projectUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<ProjectUserAssignment>> {
        return this.api.projectReqSub(new ProjectCtlProtoReq(
            { listUsers: new ProjectCtlProtoListUsersReq({ projectUuid: stringToBytes(projectUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listUsers.users.map(partialProjectUserAssignmentOf))));
    }

    assignUser(props: { projectUuid: string, userUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.projectReq(new ProjectCtlProtoReq({
            assignUser: new ProjectCtlProtoAssignUserReq({
                projectUuid: stringToBytes(props.projectUuid),
                userUuid: stringToBytes(props.userUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }

    unassignUser(props: { projectUuid: string, userUuid: string }): Observable<Ok> {
        return this.api.projectReq(new ProjectCtlProtoReq({
            unassignUser: new ProjectCtlProtoUnassignUserReq(
                { projectUuid: stringToBytes(props.projectUuid), userUuid: stringToBytes(props.userUuid) }
            )
        }));
    }

    setUserAccessLevel(props: { projectUuid: string, userUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.projectReq(new ProjectCtlProtoReq({
            setUserAccessLevel: new ProjectCtlProtoSetUserAccessLevelReq({
                projectUuid: stringToBytes(props.projectUuid),
                userUuid: stringToBytes(props.userUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }

    listTeams(projectUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<ProjectTeamAssignment>> {
        return this.api.projectReqSub(new ProjectCtlProtoReq(
            { listTeams: new ProjectCtlProtoListTeamsReq({ projectUuid: stringToBytes(projectUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listTeams.teams.map(partialProjectTeamAssignmentOf))));
    }

    assignTeam(props: { projectUuid: string, teamUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.projectReq(new ProjectCtlProtoReq({
            assignTeam: new ProjectCtlProtoAssignTeamReq({
                projectUuid: stringToBytes(props.projectUuid),
                teamUuid: stringToBytes(props.teamUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }

    unassignTeam(props: { projectUuid: string, teamUuid: string }): Observable<Ok> {
        return this.api.projectReq(new ProjectCtlProtoReq({
            unassignTeam: new ProjectCtlProtoUnassignTeamReq(
                { projectUuid: stringToBytes(props.projectUuid), teamUuid: stringToBytes(props.teamUuid) }
            )
        }));
    }

    setTeamAccessLevel(props: { projectUuid: string, teamUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.projectReq(new ProjectCtlProtoReq({
            setTeamAccessLevel: new ProjectCtlProtoSetTeamAccessLevelReq({
                projectUuid: stringToBytes(props.projectUuid),
                teamUuid: stringToBytes(props.teamUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }
}
