/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { Deployment, DeploymentColumn } from "../../../concept/deployment";
import { MatSortModule } from "@angular/material/sort";
import { ResourceTable, ResourceTableService } from "../../../service/resource-table.service";
import { DeploymentStatusPipe } from "../deployment-status.pipe";
import { RegionPipe } from "../region.pipe";
import { DatetimePipe, MenuItem, SpinnerComponent } from "typedb-platform-framework";
import { StatusIcon, TableSelectionCellComponent, TableSelectionHeaderCellComponent, TableStatusCellComponent } from "../../../framework/table";
import { Router } from "@angular/router";
import { projectDetailsPath } from "../../../routing/resource-paths";
import { PartialWithId } from "../../../concept/base";
import { Project } from "../../../concept/project";
import { ApplicationState } from "../../../service/application-state.service";
import { AccessLevel } from "../../../concept/iam";
import { MatMenuModule } from "@angular/material/menu";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: "tp-deployments-table",
    templateUrl: "./deployments-table.component.html",
    standalone: true,
    imports: [
        AsyncPipe, DatetimePipe, MatTableModule, MatSortModule, MatMenuModule, MatTooltipModule, MatButtonModule, FontAwesomeModule,
        TableSelectionHeaderCellComponent, TableSelectionCellComponent, RegionPipe, DeploymentStatusPipe, TableStatusCellComponent, SpinnerComponent,
    ],
})
export class DeploymentsTableComponent {
    @Input({ required: true }) table!: ResourceTable<Deployment, DeploymentColumn>;
    @Input() rowPopupMenuItems?: ((row: Deployment, access: AccessLevel) => MenuItem[]) | null;

    constructor(public tableService: ResourceTableService, private router: Router, private app: ApplicationState) {}

    deploymentIcon(deployment: Deployment): StatusIcon {
        switch (deployment.status) {
            case "running":
                return { color: "ok" };

            case "starting":
            case "resuming":
            case "suspending":
            case "destroying":
                return { loading: true };

            case "suspended":
                return { color: "inactive" };
            case "destroyed":
                return { color: "inactive" };
            case "error":
                return { color: "error" };
        }
    }

    openProject(project: PartialWithId<Project>) {
        this.router.navigate([projectDetailsPath(project, this.app.requireCurrentOrg())]);
    }
}
