<img class="tb-logo" src="https://typedb.com/assets/logo/typedb.svg" alt="TypeDB Cloud"/>
<div class="flex-spacer"></div>
@if (!avatarSectionHidden) {
  <button mat-button type="button" class="tb-settings-panel" (click)="avatarClick.emit()">
    <div style="display: flex; flex-direction: row; align-items: center">
      <span>{{ (userId$ | async) ?? "" }}</span>
      <tp-user-avatar [userEmail]="userEmail$ | async"/>
    </div>
  </button>
}
