/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: application/protocol/deployment-controller.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../protocol/concept";
import * as pb_1 from "google-protobuf";
export class DeploymentCtlProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProto {
        const message = new DeploymentCtlProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProto {
        return DeploymentCtlProto.deserialize(bytes);
    }
}
export class DeploymentCtlProtoReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: DeploymentCtlProtoGetAccessLevelsReq;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: DeploymentCtlProtoGetLatestTypeDBReq;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: DeploymentCtlProtoGetDeploymentOptionsReq;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: DeploymentCtlProtoGetDeploymentPriceReq;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: DeploymentCtlProtoCreateDeploymentReq;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: DeploymentCtlProtoGetDeploymentReq;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: DeploymentCtlProtoUpdateDeploymentReq;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: DeploymentCtlProtoUpdateToLatestTypeDBReq;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: DeploymentCtlProtoSuspendDeploymentReq;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: DeploymentCtlProtoResumeDeploymentReq;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: DeploymentCtlProtoDestroyDeploymentReq;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: DeploymentCtlProtoListServersReq;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: DeploymentCtlProtoListServerLogsReq;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: DeploymentCtlProtoInitAdminUserReq;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: DeploymentCtlProtoCreateDatabaseReq;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: DeploymentCtlProtoListSampleDatasetsReq;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        createDeployment?: never;
        getDeployment?: never;
        updateDeployment?: never;
        updateToLatestTypedb?: never;
        suspendDeployment?: never;
        resumeDeployment?: never;
        destroyDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: DeploymentCtlProtoLoadSampleDatasetReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("getLatestTypedb" in data && data.getLatestTypedb != undefined) {
                this.getLatestTypedb = data.getLatestTypedb;
            }
            if ("getDeploymentOptions" in data && data.getDeploymentOptions != undefined) {
                this.getDeploymentOptions = data.getDeploymentOptions;
            }
            if ("getDeploymentPrice" in data && data.getDeploymentPrice != undefined) {
                this.getDeploymentPrice = data.getDeploymentPrice;
            }
            if ("createDeployment" in data && data.createDeployment != undefined) {
                this.createDeployment = data.createDeployment;
            }
            if ("getDeployment" in data && data.getDeployment != undefined) {
                this.getDeployment = data.getDeployment;
            }
            if ("updateDeployment" in data && data.updateDeployment != undefined) {
                this.updateDeployment = data.updateDeployment;
            }
            if ("updateToLatestTypedb" in data && data.updateToLatestTypedb != undefined) {
                this.updateToLatestTypedb = data.updateToLatestTypedb;
            }
            if ("suspendDeployment" in data && data.suspendDeployment != undefined) {
                this.suspendDeployment = data.suspendDeployment;
            }
            if ("resumeDeployment" in data && data.resumeDeployment != undefined) {
                this.resumeDeployment = data.resumeDeployment;
            }
            if ("destroyDeployment" in data && data.destroyDeployment != undefined) {
                this.destroyDeployment = data.destroyDeployment;
            }
            if ("listServers" in data && data.listServers != undefined) {
                this.listServers = data.listServers;
            }
            if ("listServerLogs" in data && data.listServerLogs != undefined) {
                this.listServerLogs = data.listServerLogs;
            }
            if ("initAdminUser" in data && data.initAdminUser != undefined) {
                this.initAdminUser = data.initAdminUser;
            }
            if ("createDatabase" in data && data.createDatabase != undefined) {
                this.createDatabase = data.createDatabase;
            }
            if ("listSampleDatasets" in data && data.listSampleDatasets != undefined) {
                this.listSampleDatasets = data.listSampleDatasets;
            }
            if ("loadSampleDataset" in data && data.loadSampleDataset != undefined) {
                this.loadSampleDataset = data.loadSampleDataset;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoGetAccessLevelsReq, 1) as DeploymentCtlProtoGetAccessLevelsReq;
    }
    set getAccessLevels(value: DeploymentCtlProtoGetAccessLevelsReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get getLatestTypedb() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoGetLatestTypeDBReq, 2) as DeploymentCtlProtoGetLatestTypeDBReq;
    }
    set getLatestTypedb(value: DeploymentCtlProtoGetLatestTypeDBReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasGetLatestTypedb() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get getDeploymentOptions() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoGetDeploymentOptionsReq, 3) as DeploymentCtlProtoGetDeploymentOptionsReq;
    }
    set getDeploymentOptions(value: DeploymentCtlProtoGetDeploymentOptionsReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasGetDeploymentOptions() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getDeploymentPrice() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoGetDeploymentPriceReq, 4) as DeploymentCtlProtoGetDeploymentPriceReq;
    }
    set getDeploymentPrice(value: DeploymentCtlProtoGetDeploymentPriceReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetDeploymentPrice() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get createDeployment() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoCreateDeploymentReq, 5) as DeploymentCtlProtoCreateDeploymentReq;
    }
    set createDeployment(value: DeploymentCtlProtoCreateDeploymentReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasCreateDeployment() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get getDeployment() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoGetDeploymentReq, 6) as DeploymentCtlProtoGetDeploymentReq;
    }
    set getDeployment(value: DeploymentCtlProtoGetDeploymentReq) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasGetDeployment() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get updateDeployment() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoUpdateDeploymentReq, 7) as DeploymentCtlProtoUpdateDeploymentReq;
    }
    set updateDeployment(value: DeploymentCtlProtoUpdateDeploymentReq) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasUpdateDeployment() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get updateToLatestTypedb() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoUpdateToLatestTypeDBReq, 8) as DeploymentCtlProtoUpdateToLatestTypeDBReq;
    }
    set updateToLatestTypedb(value: DeploymentCtlProtoUpdateToLatestTypeDBReq) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasUpdateToLatestTypedb() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get suspendDeployment() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoSuspendDeploymentReq, 9) as DeploymentCtlProtoSuspendDeploymentReq;
    }
    set suspendDeployment(value: DeploymentCtlProtoSuspendDeploymentReq) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasSuspendDeployment() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get resumeDeployment() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoResumeDeploymentReq, 10) as DeploymentCtlProtoResumeDeploymentReq;
    }
    set resumeDeployment(value: DeploymentCtlProtoResumeDeploymentReq) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasResumeDeployment() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get destroyDeployment() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoDestroyDeploymentReq, 11) as DeploymentCtlProtoDestroyDeploymentReq;
    }
    set destroyDeployment(value: DeploymentCtlProtoDestroyDeploymentReq) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasDestroyDeployment() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get listServers() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoListServersReq, 12) as DeploymentCtlProtoListServersReq;
    }
    set listServers(value: DeploymentCtlProtoListServersReq) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasListServers() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get listServerLogs() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoListServerLogsReq, 13) as DeploymentCtlProtoListServerLogsReq;
    }
    set listServerLogs(value: DeploymentCtlProtoListServerLogsReq) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
    }
    get hasListServerLogs() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get initAdminUser() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoInitAdminUserReq, 14) as DeploymentCtlProtoInitAdminUserReq;
    }
    set initAdminUser(value: DeploymentCtlProtoInitAdminUserReq) {
        pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[0], value);
    }
    get hasInitAdminUser() {
        return pb_1.Message.getField(this, 14) != null;
    }
    get createDatabase() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoCreateDatabaseReq, 15) as DeploymentCtlProtoCreateDatabaseReq;
    }
    set createDatabase(value: DeploymentCtlProtoCreateDatabaseReq) {
        pb_1.Message.setOneofWrapperField(this, 15, this.#one_of_decls[0], value);
    }
    get hasCreateDatabase() {
        return pb_1.Message.getField(this, 15) != null;
    }
    get listSampleDatasets() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoListSampleDatasetsReq, 17) as DeploymentCtlProtoListSampleDatasetsReq;
    }
    set listSampleDatasets(value: DeploymentCtlProtoListSampleDatasetsReq) {
        pb_1.Message.setOneofWrapperField(this, 17, this.#one_of_decls[0], value);
    }
    get hasListSampleDatasets() {
        return pb_1.Message.getField(this, 17) != null;
    }
    get loadSampleDataset() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoLoadSampleDatasetReq, 18) as DeploymentCtlProtoLoadSampleDatasetReq;
    }
    set loadSampleDataset(value: DeploymentCtlProtoLoadSampleDatasetReq) {
        pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0], value);
    }
    get hasLoadSampleDataset() {
        return pb_1.Message.getField(this, 18) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "getLatestTypedb" | "getDeploymentOptions" | "getDeploymentPrice" | "createDeployment" | "getDeployment" | "updateDeployment" | "updateToLatestTypedb" | "suspendDeployment" | "resumeDeployment" | "destroyDeployment" | "listServers" | "listServerLogs" | "initAdminUser" | "createDatabase" | "listSampleDatasets" | "loadSampleDataset";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "getLatestTypedb",
            3: "getDeploymentOptions",
            4: "getDeploymentPrice",
            5: "createDeployment",
            6: "getDeployment",
            7: "updateDeployment",
            8: "updateToLatestTypedb",
            9: "suspendDeployment",
            10: "resumeDeployment",
            11: "destroyDeployment",
            12: "listServers",
            13: "listServerLogs",
            14: "initAdminUser",
            15: "createDatabase",
            17: "listSampleDatasets",
            18: "loadSampleDataset"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof DeploymentCtlProtoGetAccessLevelsReq.prototype.toObject>;
        getLatestTypedb?: ReturnType<typeof DeploymentCtlProtoGetLatestTypeDBReq.prototype.toObject>;
        getDeploymentOptions?: ReturnType<typeof DeploymentCtlProtoGetDeploymentOptionsReq.prototype.toObject>;
        getDeploymentPrice?: ReturnType<typeof DeploymentCtlProtoGetDeploymentPriceReq.prototype.toObject>;
        createDeployment?: ReturnType<typeof DeploymentCtlProtoCreateDeploymentReq.prototype.toObject>;
        getDeployment?: ReturnType<typeof DeploymentCtlProtoGetDeploymentReq.prototype.toObject>;
        updateDeployment?: ReturnType<typeof DeploymentCtlProtoUpdateDeploymentReq.prototype.toObject>;
        updateToLatestTypedb?: ReturnType<typeof DeploymentCtlProtoUpdateToLatestTypeDBReq.prototype.toObject>;
        suspendDeployment?: ReturnType<typeof DeploymentCtlProtoSuspendDeploymentReq.prototype.toObject>;
        resumeDeployment?: ReturnType<typeof DeploymentCtlProtoResumeDeploymentReq.prototype.toObject>;
        destroyDeployment?: ReturnType<typeof DeploymentCtlProtoDestroyDeploymentReq.prototype.toObject>;
        listServers?: ReturnType<typeof DeploymentCtlProtoListServersReq.prototype.toObject>;
        listServerLogs?: ReturnType<typeof DeploymentCtlProtoListServerLogsReq.prototype.toObject>;
        initAdminUser?: ReturnType<typeof DeploymentCtlProtoInitAdminUserReq.prototype.toObject>;
        createDatabase?: ReturnType<typeof DeploymentCtlProtoCreateDatabaseReq.prototype.toObject>;
        listSampleDatasets?: ReturnType<typeof DeploymentCtlProtoListSampleDatasetsReq.prototype.toObject>;
        loadSampleDataset?: ReturnType<typeof DeploymentCtlProtoLoadSampleDatasetReq.prototype.toObject>;
    }): DeploymentCtlProtoReq {
        const message = new DeploymentCtlProtoReq({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = DeploymentCtlProtoGetAccessLevelsReq.fromObject(data.getAccessLevels);
        }
        if (data.getLatestTypedb != null) {
            message.getLatestTypedb = DeploymentCtlProtoGetLatestTypeDBReq.fromObject(data.getLatestTypedb);
        }
        if (data.getDeploymentOptions != null) {
            message.getDeploymentOptions = DeploymentCtlProtoGetDeploymentOptionsReq.fromObject(data.getDeploymentOptions);
        }
        if (data.getDeploymentPrice != null) {
            message.getDeploymentPrice = DeploymentCtlProtoGetDeploymentPriceReq.fromObject(data.getDeploymentPrice);
        }
        if (data.createDeployment != null) {
            message.createDeployment = DeploymentCtlProtoCreateDeploymentReq.fromObject(data.createDeployment);
        }
        if (data.getDeployment != null) {
            message.getDeployment = DeploymentCtlProtoGetDeploymentReq.fromObject(data.getDeployment);
        }
        if (data.updateDeployment != null) {
            message.updateDeployment = DeploymentCtlProtoUpdateDeploymentReq.fromObject(data.updateDeployment);
        }
        if (data.updateToLatestTypedb != null) {
            message.updateToLatestTypedb = DeploymentCtlProtoUpdateToLatestTypeDBReq.fromObject(data.updateToLatestTypedb);
        }
        if (data.suspendDeployment != null) {
            message.suspendDeployment = DeploymentCtlProtoSuspendDeploymentReq.fromObject(data.suspendDeployment);
        }
        if (data.resumeDeployment != null) {
            message.resumeDeployment = DeploymentCtlProtoResumeDeploymentReq.fromObject(data.resumeDeployment);
        }
        if (data.destroyDeployment != null) {
            message.destroyDeployment = DeploymentCtlProtoDestroyDeploymentReq.fromObject(data.destroyDeployment);
        }
        if (data.listServers != null) {
            message.listServers = DeploymentCtlProtoListServersReq.fromObject(data.listServers);
        }
        if (data.listServerLogs != null) {
            message.listServerLogs = DeploymentCtlProtoListServerLogsReq.fromObject(data.listServerLogs);
        }
        if (data.initAdminUser != null) {
            message.initAdminUser = DeploymentCtlProtoInitAdminUserReq.fromObject(data.initAdminUser);
        }
        if (data.createDatabase != null) {
            message.createDatabase = DeploymentCtlProtoCreateDatabaseReq.fromObject(data.createDatabase);
        }
        if (data.listSampleDatasets != null) {
            message.listSampleDatasets = DeploymentCtlProtoListSampleDatasetsReq.fromObject(data.listSampleDatasets);
        }
        if (data.loadSampleDataset != null) {
            message.loadSampleDataset = DeploymentCtlProtoLoadSampleDatasetReq.fromObject(data.loadSampleDataset);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof DeploymentCtlProtoGetAccessLevelsReq.prototype.toObject>;
            getLatestTypedb?: ReturnType<typeof DeploymentCtlProtoGetLatestTypeDBReq.prototype.toObject>;
            getDeploymentOptions?: ReturnType<typeof DeploymentCtlProtoGetDeploymentOptionsReq.prototype.toObject>;
            getDeploymentPrice?: ReturnType<typeof DeploymentCtlProtoGetDeploymentPriceReq.prototype.toObject>;
            createDeployment?: ReturnType<typeof DeploymentCtlProtoCreateDeploymentReq.prototype.toObject>;
            getDeployment?: ReturnType<typeof DeploymentCtlProtoGetDeploymentReq.prototype.toObject>;
            updateDeployment?: ReturnType<typeof DeploymentCtlProtoUpdateDeploymentReq.prototype.toObject>;
            updateToLatestTypedb?: ReturnType<typeof DeploymentCtlProtoUpdateToLatestTypeDBReq.prototype.toObject>;
            suspendDeployment?: ReturnType<typeof DeploymentCtlProtoSuspendDeploymentReq.prototype.toObject>;
            resumeDeployment?: ReturnType<typeof DeploymentCtlProtoResumeDeploymentReq.prototype.toObject>;
            destroyDeployment?: ReturnType<typeof DeploymentCtlProtoDestroyDeploymentReq.prototype.toObject>;
            listServers?: ReturnType<typeof DeploymentCtlProtoListServersReq.prototype.toObject>;
            listServerLogs?: ReturnType<typeof DeploymentCtlProtoListServerLogsReq.prototype.toObject>;
            initAdminUser?: ReturnType<typeof DeploymentCtlProtoInitAdminUserReq.prototype.toObject>;
            createDatabase?: ReturnType<typeof DeploymentCtlProtoCreateDatabaseReq.prototype.toObject>;
            listSampleDatasets?: ReturnType<typeof DeploymentCtlProtoListSampleDatasetsReq.prototype.toObject>;
            loadSampleDataset?: ReturnType<typeof DeploymentCtlProtoLoadSampleDatasetReq.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.getLatestTypedb != null) {
            data.getLatestTypedb = this.getLatestTypedb.toObject();
        }
        if (this.getDeploymentOptions != null) {
            data.getDeploymentOptions = this.getDeploymentOptions.toObject();
        }
        if (this.getDeploymentPrice != null) {
            data.getDeploymentPrice = this.getDeploymentPrice.toObject();
        }
        if (this.createDeployment != null) {
            data.createDeployment = this.createDeployment.toObject();
        }
        if (this.getDeployment != null) {
            data.getDeployment = this.getDeployment.toObject();
        }
        if (this.updateDeployment != null) {
            data.updateDeployment = this.updateDeployment.toObject();
        }
        if (this.updateToLatestTypedb != null) {
            data.updateToLatestTypedb = this.updateToLatestTypedb.toObject();
        }
        if (this.suspendDeployment != null) {
            data.suspendDeployment = this.suspendDeployment.toObject();
        }
        if (this.resumeDeployment != null) {
            data.resumeDeployment = this.resumeDeployment.toObject();
        }
        if (this.destroyDeployment != null) {
            data.destroyDeployment = this.destroyDeployment.toObject();
        }
        if (this.listServers != null) {
            data.listServers = this.listServers.toObject();
        }
        if (this.listServerLogs != null) {
            data.listServerLogs = this.listServerLogs.toObject();
        }
        if (this.initAdminUser != null) {
            data.initAdminUser = this.initAdminUser.toObject();
        }
        if (this.createDatabase != null) {
            data.createDatabase = this.createDatabase.toObject();
        }
        if (this.listSampleDatasets != null) {
            data.listSampleDatasets = this.listSampleDatasets.toObject();
        }
        if (this.loadSampleDataset != null) {
            data.loadSampleDataset = this.loadSampleDataset.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasGetLatestTypedb)
            writer.writeMessage(2, this.getLatestTypedb, () => this.getLatestTypedb.serialize(writer));
        if (this.hasGetDeploymentOptions)
            writer.writeMessage(3, this.getDeploymentOptions, () => this.getDeploymentOptions.serialize(writer));
        if (this.hasGetDeploymentPrice)
            writer.writeMessage(4, this.getDeploymentPrice, () => this.getDeploymentPrice.serialize(writer));
        if (this.hasCreateDeployment)
            writer.writeMessage(5, this.createDeployment, () => this.createDeployment.serialize(writer));
        if (this.hasGetDeployment)
            writer.writeMessage(6, this.getDeployment, () => this.getDeployment.serialize(writer));
        if (this.hasUpdateDeployment)
            writer.writeMessage(7, this.updateDeployment, () => this.updateDeployment.serialize(writer));
        if (this.hasUpdateToLatestTypedb)
            writer.writeMessage(8, this.updateToLatestTypedb, () => this.updateToLatestTypedb.serialize(writer));
        if (this.hasSuspendDeployment)
            writer.writeMessage(9, this.suspendDeployment, () => this.suspendDeployment.serialize(writer));
        if (this.hasResumeDeployment)
            writer.writeMessage(10, this.resumeDeployment, () => this.resumeDeployment.serialize(writer));
        if (this.hasDestroyDeployment)
            writer.writeMessage(11, this.destroyDeployment, () => this.destroyDeployment.serialize(writer));
        if (this.hasListServers)
            writer.writeMessage(12, this.listServers, () => this.listServers.serialize(writer));
        if (this.hasListServerLogs)
            writer.writeMessage(13, this.listServerLogs, () => this.listServerLogs.serialize(writer));
        if (this.hasInitAdminUser)
            writer.writeMessage(14, this.initAdminUser, () => this.initAdminUser.serialize(writer));
        if (this.hasCreateDatabase)
            writer.writeMessage(15, this.createDatabase, () => this.createDatabase.serialize(writer));
        if (this.hasListSampleDatasets)
            writer.writeMessage(17, this.listSampleDatasets, () => this.listSampleDatasets.serialize(writer));
        if (this.hasLoadSampleDataset)
            writer.writeMessage(18, this.loadSampleDataset, () => this.loadSampleDataset.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = DeploymentCtlProtoGetAccessLevelsReq.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.getLatestTypedb, () => message.getLatestTypedb = DeploymentCtlProtoGetLatestTypeDBReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.getDeploymentOptions, () => message.getDeploymentOptions = DeploymentCtlProtoGetDeploymentOptionsReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getDeploymentPrice, () => message.getDeploymentPrice = DeploymentCtlProtoGetDeploymentPriceReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.createDeployment, () => message.createDeployment = DeploymentCtlProtoCreateDeploymentReq.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.getDeployment, () => message.getDeployment = DeploymentCtlProtoGetDeploymentReq.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.updateDeployment, () => message.updateDeployment = DeploymentCtlProtoUpdateDeploymentReq.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.updateToLatestTypedb, () => message.updateToLatestTypedb = DeploymentCtlProtoUpdateToLatestTypeDBReq.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.suspendDeployment, () => message.suspendDeployment = DeploymentCtlProtoSuspendDeploymentReq.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.resumeDeployment, () => message.resumeDeployment = DeploymentCtlProtoResumeDeploymentReq.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.destroyDeployment, () => message.destroyDeployment = DeploymentCtlProtoDestroyDeploymentReq.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.listServers, () => message.listServers = DeploymentCtlProtoListServersReq.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.listServerLogs, () => message.listServerLogs = DeploymentCtlProtoListServerLogsReq.deserialize(reader));
                    break;
                case 14:
                    reader.readMessage(message.initAdminUser, () => message.initAdminUser = DeploymentCtlProtoInitAdminUserReq.deserialize(reader));
                    break;
                case 15:
                    reader.readMessage(message.createDatabase, () => message.createDatabase = DeploymentCtlProtoCreateDatabaseReq.deserialize(reader));
                    break;
                case 17:
                    reader.readMessage(message.listSampleDatasets, () => message.listSampleDatasets = DeploymentCtlProtoListSampleDatasetsReq.deserialize(reader));
                    break;
                case 18:
                    reader.readMessage(message.loadSampleDataset, () => message.loadSampleDataset = DeploymentCtlProtoLoadSampleDatasetReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoReq {
        return DeploymentCtlProtoReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: DeploymentCtlProtoGetAccessLevelsRes;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        getDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: DeploymentCtlProtoGetLatestTypeDBRes;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        getDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: DeploymentCtlProtoGetDeploymentOptionsRes;
        getDeploymentPrice?: never;
        getDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: DeploymentCtlProtoGetDeploymentPriceRes;
        getDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        getDeployment?: DeploymentCtlProtoGetDeploymentRes;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        getDeployment?: never;
        listServers?: DeploymentCtlProtoListServersRes;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        getDeployment?: never;
        listServers?: never;
        listServerLogs?: DeploymentCtlProtoListServerLogsRes;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        getDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: DeploymentCtlProtoInitAdminUserRes;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        getDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: DeploymentCtlProtoCreateDatabaseRes;
        listSampleDatasets?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        getDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: DeploymentCtlProtoListSampleDatasetsRes;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getDeploymentOptions?: never;
        getDeploymentPrice?: never;
        getDeployment?: never;
        listServers?: never;
        listServerLogs?: never;
        initAdminUser?: never;
        createDatabase?: never;
        listSampleDatasets?: never;
        loadSampleDataset?: DeploymentCtlProtoLoadSampleDatasetRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("getLatestTypedb" in data && data.getLatestTypedb != undefined) {
                this.getLatestTypedb = data.getLatestTypedb;
            }
            if ("getDeploymentOptions" in data && data.getDeploymentOptions != undefined) {
                this.getDeploymentOptions = data.getDeploymentOptions;
            }
            if ("getDeploymentPrice" in data && data.getDeploymentPrice != undefined) {
                this.getDeploymentPrice = data.getDeploymentPrice;
            }
            if ("getDeployment" in data && data.getDeployment != undefined) {
                this.getDeployment = data.getDeployment;
            }
            if ("listServers" in data && data.listServers != undefined) {
                this.listServers = data.listServers;
            }
            if ("listServerLogs" in data && data.listServerLogs != undefined) {
                this.listServerLogs = data.listServerLogs;
            }
            if ("initAdminUser" in data && data.initAdminUser != undefined) {
                this.initAdminUser = data.initAdminUser;
            }
            if ("createDatabase" in data && data.createDatabase != undefined) {
                this.createDatabase = data.createDatabase;
            }
            if ("listSampleDatasets" in data && data.listSampleDatasets != undefined) {
                this.listSampleDatasets = data.listSampleDatasets;
            }
            if ("loadSampleDataset" in data && data.loadSampleDataset != undefined) {
                this.loadSampleDataset = data.loadSampleDataset;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoGetAccessLevelsRes, 1) as DeploymentCtlProtoGetAccessLevelsRes;
    }
    set getAccessLevels(value: DeploymentCtlProtoGetAccessLevelsRes) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get getLatestTypedb() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoGetLatestTypeDBRes, 2) as DeploymentCtlProtoGetLatestTypeDBRes;
    }
    set getLatestTypedb(value: DeploymentCtlProtoGetLatestTypeDBRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasGetLatestTypedb() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get getDeploymentOptions() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoGetDeploymentOptionsRes, 3) as DeploymentCtlProtoGetDeploymentOptionsRes;
    }
    set getDeploymentOptions(value: DeploymentCtlProtoGetDeploymentOptionsRes) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasGetDeploymentOptions() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getDeploymentPrice() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoGetDeploymentPriceRes, 4) as DeploymentCtlProtoGetDeploymentPriceRes;
    }
    set getDeploymentPrice(value: DeploymentCtlProtoGetDeploymentPriceRes) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetDeploymentPrice() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get getDeployment() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoGetDeploymentRes, 5) as DeploymentCtlProtoGetDeploymentRes;
    }
    set getDeployment(value: DeploymentCtlProtoGetDeploymentRes) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasGetDeployment() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get listServers() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoListServersRes, 6) as DeploymentCtlProtoListServersRes;
    }
    set listServers(value: DeploymentCtlProtoListServersRes) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasListServers() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get listServerLogs() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoListServerLogsRes, 7) as DeploymentCtlProtoListServerLogsRes;
    }
    set listServerLogs(value: DeploymentCtlProtoListServerLogsRes) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasListServerLogs() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get initAdminUser() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoInitAdminUserRes, 8) as DeploymentCtlProtoInitAdminUserRes;
    }
    set initAdminUser(value: DeploymentCtlProtoInitAdminUserRes) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasInitAdminUser() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get createDatabase() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoCreateDatabaseRes, 9) as DeploymentCtlProtoCreateDatabaseRes;
    }
    set createDatabase(value: DeploymentCtlProtoCreateDatabaseRes) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasCreateDatabase() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get listSampleDatasets() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoListSampleDatasetsRes, 10) as DeploymentCtlProtoListSampleDatasetsRes;
    }
    set listSampleDatasets(value: DeploymentCtlProtoListSampleDatasetsRes) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasListSampleDatasets() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get loadSampleDataset() {
        return pb_1.Message.getWrapperField(this, DeploymentCtlProtoLoadSampleDatasetRes, 11) as DeploymentCtlProtoLoadSampleDatasetRes;
    }
    set loadSampleDataset(value: DeploymentCtlProtoLoadSampleDatasetRes) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasLoadSampleDataset() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "getLatestTypedb" | "getDeploymentOptions" | "getDeploymentPrice" | "getDeployment" | "listServers" | "listServerLogs" | "initAdminUser" | "createDatabase" | "listSampleDatasets" | "loadSampleDataset";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "getLatestTypedb",
            3: "getDeploymentOptions",
            4: "getDeploymentPrice",
            5: "getDeployment",
            6: "listServers",
            7: "listServerLogs",
            8: "initAdminUser",
            9: "createDatabase",
            10: "listSampleDatasets",
            11: "loadSampleDataset"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof DeploymentCtlProtoGetAccessLevelsRes.prototype.toObject>;
        getLatestTypedb?: ReturnType<typeof DeploymentCtlProtoGetLatestTypeDBRes.prototype.toObject>;
        getDeploymentOptions?: ReturnType<typeof DeploymentCtlProtoGetDeploymentOptionsRes.prototype.toObject>;
        getDeploymentPrice?: ReturnType<typeof DeploymentCtlProtoGetDeploymentPriceRes.prototype.toObject>;
        getDeployment?: ReturnType<typeof DeploymentCtlProtoGetDeploymentRes.prototype.toObject>;
        listServers?: ReturnType<typeof DeploymentCtlProtoListServersRes.prototype.toObject>;
        listServerLogs?: ReturnType<typeof DeploymentCtlProtoListServerLogsRes.prototype.toObject>;
        initAdminUser?: ReturnType<typeof DeploymentCtlProtoInitAdminUserRes.prototype.toObject>;
        createDatabase?: ReturnType<typeof DeploymentCtlProtoCreateDatabaseRes.prototype.toObject>;
        listSampleDatasets?: ReturnType<typeof DeploymentCtlProtoListSampleDatasetsRes.prototype.toObject>;
        loadSampleDataset?: ReturnType<typeof DeploymentCtlProtoLoadSampleDatasetRes.prototype.toObject>;
    }): DeploymentCtlProtoRes {
        const message = new DeploymentCtlProtoRes({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = DeploymentCtlProtoGetAccessLevelsRes.fromObject(data.getAccessLevels);
        }
        if (data.getLatestTypedb != null) {
            message.getLatestTypedb = DeploymentCtlProtoGetLatestTypeDBRes.fromObject(data.getLatestTypedb);
        }
        if (data.getDeploymentOptions != null) {
            message.getDeploymentOptions = DeploymentCtlProtoGetDeploymentOptionsRes.fromObject(data.getDeploymentOptions);
        }
        if (data.getDeploymentPrice != null) {
            message.getDeploymentPrice = DeploymentCtlProtoGetDeploymentPriceRes.fromObject(data.getDeploymentPrice);
        }
        if (data.getDeployment != null) {
            message.getDeployment = DeploymentCtlProtoGetDeploymentRes.fromObject(data.getDeployment);
        }
        if (data.listServers != null) {
            message.listServers = DeploymentCtlProtoListServersRes.fromObject(data.listServers);
        }
        if (data.listServerLogs != null) {
            message.listServerLogs = DeploymentCtlProtoListServerLogsRes.fromObject(data.listServerLogs);
        }
        if (data.initAdminUser != null) {
            message.initAdminUser = DeploymentCtlProtoInitAdminUserRes.fromObject(data.initAdminUser);
        }
        if (data.createDatabase != null) {
            message.createDatabase = DeploymentCtlProtoCreateDatabaseRes.fromObject(data.createDatabase);
        }
        if (data.listSampleDatasets != null) {
            message.listSampleDatasets = DeploymentCtlProtoListSampleDatasetsRes.fromObject(data.listSampleDatasets);
        }
        if (data.loadSampleDataset != null) {
            message.loadSampleDataset = DeploymentCtlProtoLoadSampleDatasetRes.fromObject(data.loadSampleDataset);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof DeploymentCtlProtoGetAccessLevelsRes.prototype.toObject>;
            getLatestTypedb?: ReturnType<typeof DeploymentCtlProtoGetLatestTypeDBRes.prototype.toObject>;
            getDeploymentOptions?: ReturnType<typeof DeploymentCtlProtoGetDeploymentOptionsRes.prototype.toObject>;
            getDeploymentPrice?: ReturnType<typeof DeploymentCtlProtoGetDeploymentPriceRes.prototype.toObject>;
            getDeployment?: ReturnType<typeof DeploymentCtlProtoGetDeploymentRes.prototype.toObject>;
            listServers?: ReturnType<typeof DeploymentCtlProtoListServersRes.prototype.toObject>;
            listServerLogs?: ReturnType<typeof DeploymentCtlProtoListServerLogsRes.prototype.toObject>;
            initAdminUser?: ReturnType<typeof DeploymentCtlProtoInitAdminUserRes.prototype.toObject>;
            createDatabase?: ReturnType<typeof DeploymentCtlProtoCreateDatabaseRes.prototype.toObject>;
            listSampleDatasets?: ReturnType<typeof DeploymentCtlProtoListSampleDatasetsRes.prototype.toObject>;
            loadSampleDataset?: ReturnType<typeof DeploymentCtlProtoLoadSampleDatasetRes.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.getLatestTypedb != null) {
            data.getLatestTypedb = this.getLatestTypedb.toObject();
        }
        if (this.getDeploymentOptions != null) {
            data.getDeploymentOptions = this.getDeploymentOptions.toObject();
        }
        if (this.getDeploymentPrice != null) {
            data.getDeploymentPrice = this.getDeploymentPrice.toObject();
        }
        if (this.getDeployment != null) {
            data.getDeployment = this.getDeployment.toObject();
        }
        if (this.listServers != null) {
            data.listServers = this.listServers.toObject();
        }
        if (this.listServerLogs != null) {
            data.listServerLogs = this.listServerLogs.toObject();
        }
        if (this.initAdminUser != null) {
            data.initAdminUser = this.initAdminUser.toObject();
        }
        if (this.createDatabase != null) {
            data.createDatabase = this.createDatabase.toObject();
        }
        if (this.listSampleDatasets != null) {
            data.listSampleDatasets = this.listSampleDatasets.toObject();
        }
        if (this.loadSampleDataset != null) {
            data.loadSampleDataset = this.loadSampleDataset.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasGetLatestTypedb)
            writer.writeMessage(2, this.getLatestTypedb, () => this.getLatestTypedb.serialize(writer));
        if (this.hasGetDeploymentOptions)
            writer.writeMessage(3, this.getDeploymentOptions, () => this.getDeploymentOptions.serialize(writer));
        if (this.hasGetDeploymentPrice)
            writer.writeMessage(4, this.getDeploymentPrice, () => this.getDeploymentPrice.serialize(writer));
        if (this.hasGetDeployment)
            writer.writeMessage(5, this.getDeployment, () => this.getDeployment.serialize(writer));
        if (this.hasListServers)
            writer.writeMessage(6, this.listServers, () => this.listServers.serialize(writer));
        if (this.hasListServerLogs)
            writer.writeMessage(7, this.listServerLogs, () => this.listServerLogs.serialize(writer));
        if (this.hasInitAdminUser)
            writer.writeMessage(8, this.initAdminUser, () => this.initAdminUser.serialize(writer));
        if (this.hasCreateDatabase)
            writer.writeMessage(9, this.createDatabase, () => this.createDatabase.serialize(writer));
        if (this.hasListSampleDatasets)
            writer.writeMessage(10, this.listSampleDatasets, () => this.listSampleDatasets.serialize(writer));
        if (this.hasLoadSampleDataset)
            writer.writeMessage(11, this.loadSampleDataset, () => this.loadSampleDataset.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = DeploymentCtlProtoGetAccessLevelsRes.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.getLatestTypedb, () => message.getLatestTypedb = DeploymentCtlProtoGetLatestTypeDBRes.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.getDeploymentOptions, () => message.getDeploymentOptions = DeploymentCtlProtoGetDeploymentOptionsRes.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getDeploymentPrice, () => message.getDeploymentPrice = DeploymentCtlProtoGetDeploymentPriceRes.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.getDeployment, () => message.getDeployment = DeploymentCtlProtoGetDeploymentRes.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.listServers, () => message.listServers = DeploymentCtlProtoListServersRes.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.listServerLogs, () => message.listServerLogs = DeploymentCtlProtoListServerLogsRes.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.initAdminUser, () => message.initAdminUser = DeploymentCtlProtoInitAdminUserRes.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.createDatabase, () => message.createDatabase = DeploymentCtlProtoCreateDatabaseRes.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.listSampleDatasets, () => message.listSampleDatasets = DeploymentCtlProtoListSampleDatasetsRes.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.loadSampleDataset, () => message.loadSampleDataset = DeploymentCtlProtoLoadSampleDatasetRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoRes {
        return DeploymentCtlProtoRes.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetAccessLevels extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoGetAccessLevels {
        const message = new DeploymentCtlProtoGetAccessLevels({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetAccessLevels {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetAccessLevels();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetAccessLevels {
        return DeploymentCtlProtoGetAccessLevels.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetAccessLevelsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deploymentUuids?: Uint8Array[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deploymentUuids" in data && data.deploymentUuids != undefined) {
                this.deploymentUuids = data.deploymentUuids;
            }
        }
    }
    get deploymentUuids() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as Uint8Array[];
    }
    set deploymentUuids(value: Uint8Array[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        deploymentUuids?: Uint8Array[];
    }): DeploymentCtlProtoGetAccessLevelsReq {
        const message = new DeploymentCtlProtoGetAccessLevelsReq({});
        if (data.deploymentUuids != null) {
            message.deploymentUuids = data.deploymentUuids;
        }
        return message;
    }
    toObject() {
        const data: {
            deploymentUuids?: Uint8Array[];
        } = {};
        if (this.deploymentUuids != null) {
            data.deploymentUuids = this.deploymentUuids;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deploymentUuids.length)
            writer.writeRepeatedBytes(1, this.deploymentUuids);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetAccessLevelsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetAccessLevelsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readBytes());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetAccessLevelsReq {
        return DeploymentCtlProtoGetAccessLevelsReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetAccessLevelsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        accessLevels?: dependency_1.AccessLevelProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("accessLevels" in data && data.accessLevels != undefined) {
                this.accessLevels = data.accessLevels;
            }
        }
    }
    get accessLevels() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as dependency_1.AccessLevelProto[];
    }
    set accessLevels(value: dependency_1.AccessLevelProto[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        accessLevels?: dependency_1.AccessLevelProto[];
    }): DeploymentCtlProtoGetAccessLevelsRes {
        const message = new DeploymentCtlProtoGetAccessLevelsRes({});
        if (data.accessLevels != null) {
            message.accessLevels = data.accessLevels;
        }
        return message;
    }
    toObject() {
        const data: {
            accessLevels?: dependency_1.AccessLevelProto[];
        } = {};
        if (this.accessLevels != null) {
            data.accessLevels = this.accessLevels;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.accessLevels.length)
            writer.writePackedEnum(1, this.accessLevels);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetAccessLevelsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetAccessLevelsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.accessLevels = reader.readPackedEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetAccessLevelsRes {
        return DeploymentCtlProtoGetAccessLevelsRes.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetDeploymentOptions extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoGetDeploymentOptions {
        const message = new DeploymentCtlProtoGetDeploymentOptions({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetDeploymentOptions {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetDeploymentOptions();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetDeploymentOptions {
        return DeploymentCtlProtoGetDeploymentOptions.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetDeploymentOptionsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoGetDeploymentOptionsReq {
        const message = new DeploymentCtlProtoGetDeploymentOptionsReq({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetDeploymentOptionsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetDeploymentOptionsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetDeploymentOptionsReq {
        return DeploymentCtlProtoGetDeploymentOptionsReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetDeploymentOptionsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        gcp?: dependency_1.ProviderProto;
        aws?: dependency_1.ProviderProto;
        azure?: dependency_1.ProviderProto;
        typedbVersions?: string[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("gcp" in data && data.gcp != undefined) {
                this.gcp = data.gcp;
            }
            if ("aws" in data && data.aws != undefined) {
                this.aws = data.aws;
            }
            if ("azure" in data && data.azure != undefined) {
                this.azure = data.azure;
            }
            if ("typedbVersions" in data && data.typedbVersions != undefined) {
                this.typedbVersions = data.typedbVersions;
            }
        }
    }
    get gcp() {
        return pb_1.Message.getWrapperField(this, dependency_1.ProviderProto, 1) as dependency_1.ProviderProto;
    }
    set gcp(value: dependency_1.ProviderProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasGcp() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get aws() {
        return pb_1.Message.getWrapperField(this, dependency_1.ProviderProto, 2) as dependency_1.ProviderProto;
    }
    set aws(value: dependency_1.ProviderProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasAws() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get azure() {
        return pb_1.Message.getWrapperField(this, dependency_1.ProviderProto, 3) as dependency_1.ProviderProto;
    }
    set azure(value: dependency_1.ProviderProto) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasAzure() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get typedbVersions() {
        return pb_1.Message.getFieldWithDefault(this, 4, []) as string[];
    }
    set typedbVersions(value: string[]) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data: {
        gcp?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
        aws?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
        azure?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
        typedbVersions?: string[];
    }): DeploymentCtlProtoGetDeploymentOptionsRes {
        const message = new DeploymentCtlProtoGetDeploymentOptionsRes({});
        if (data.gcp != null) {
            message.gcp = dependency_1.ProviderProto.fromObject(data.gcp);
        }
        if (data.aws != null) {
            message.aws = dependency_1.ProviderProto.fromObject(data.aws);
        }
        if (data.azure != null) {
            message.azure = dependency_1.ProviderProto.fromObject(data.azure);
        }
        if (data.typedbVersions != null) {
            message.typedbVersions = data.typedbVersions;
        }
        return message;
    }
    toObject() {
        const data: {
            gcp?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
            aws?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
            azure?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
            typedbVersions?: string[];
        } = {};
        if (this.gcp != null) {
            data.gcp = this.gcp.toObject();
        }
        if (this.aws != null) {
            data.aws = this.aws.toObject();
        }
        if (this.azure != null) {
            data.azure = this.azure.toObject();
        }
        if (this.typedbVersions != null) {
            data.typedbVersions = this.typedbVersions;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGcp)
            writer.writeMessage(1, this.gcp, () => this.gcp.serialize(writer));
        if (this.hasAws)
            writer.writeMessage(2, this.aws, () => this.aws.serialize(writer));
        if (this.hasAzure)
            writer.writeMessage(3, this.azure, () => this.azure.serialize(writer));
        if (this.typedbVersions.length)
            writer.writeRepeatedString(4, this.typedbVersions);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetDeploymentOptionsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetDeploymentOptionsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.gcp, () => message.gcp = dependency_1.ProviderProto.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.aws, () => message.aws = dependency_1.ProviderProto.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.azure, () => message.azure = dependency_1.ProviderProto.deserialize(reader));
                    break;
                case 4:
                    pb_1.Message.addToRepeatedField(message, 4, reader.readString());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetDeploymentOptionsRes {
        return DeploymentCtlProtoGetDeploymentOptionsRes.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetDeploymentPrice extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoGetDeploymentPrice {
        const message = new DeploymentCtlProtoGetDeploymentPrice({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetDeploymentPrice {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetDeploymentPrice();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetDeploymentPrice {
        return DeploymentCtlProtoGetDeploymentPrice.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetDeploymentPriceReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        deployment?: dependency_1.DeploymentProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("deployment" in data && data.deployment != undefined) {
                this.deployment = data.deployment;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get deployment() {
        return pb_1.Message.getWrapperField(this, dependency_1.DeploymentProto, 2) as dependency_1.DeploymentProto;
    }
    set deployment(value: dependency_1.DeploymentProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasDeployment() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        deployment?: ReturnType<typeof dependency_1.DeploymentProto.prototype.toObject>;
    }): DeploymentCtlProtoGetDeploymentPriceReq {
        const message = new DeploymentCtlProtoGetDeploymentPriceReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.deployment != null) {
            message.deployment = dependency_1.DeploymentProto.fromObject(data.deployment);
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            deployment?: ReturnType<typeof dependency_1.DeploymentProto.prototype.toObject>;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.deployment != null) {
            data.deployment = this.deployment.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.hasDeployment)
            writer.writeMessage(2, this.deployment, () => this.deployment.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetDeploymentPriceReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetDeploymentPriceReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.deployment, () => message.deployment = dependency_1.DeploymentProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetDeploymentPriceReq {
        return DeploymentCtlProtoGetDeploymentPriceReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetDeploymentPriceRes extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        centsPerHour?: number;
    } & (({
        baseCentsPerHour?: number;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("centsPerHour" in data && data.centsPerHour != undefined) {
                this.centsPerHour = data.centsPerHour;
            }
            if ("baseCentsPerHour" in data && data.baseCentsPerHour != undefined) {
                this.baseCentsPerHour = data.baseCentsPerHour;
            }
        }
    }
    get centsPerHour() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set centsPerHour(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get baseCentsPerHour() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set baseCentsPerHour(value: number) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasBaseCentsPerHour() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _base_cents_per_hour() {
        const cases: {
            [index: number]: "none" | "baseCentsPerHour";
        } = {
            0: "none",
            2: "baseCentsPerHour"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        centsPerHour?: number;
        baseCentsPerHour?: number;
    }): DeploymentCtlProtoGetDeploymentPriceRes {
        const message = new DeploymentCtlProtoGetDeploymentPriceRes({});
        if (data.centsPerHour != null) {
            message.centsPerHour = data.centsPerHour;
        }
        if (data.baseCentsPerHour != null) {
            message.baseCentsPerHour = data.baseCentsPerHour;
        }
        return message;
    }
    toObject() {
        const data: {
            centsPerHour?: number;
            baseCentsPerHour?: number;
        } = {};
        if (this.centsPerHour != null) {
            data.centsPerHour = this.centsPerHour;
        }
        if (this.baseCentsPerHour != null) {
            data.baseCentsPerHour = this.baseCentsPerHour;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.centsPerHour != 0)
            writer.writeInt64(1, this.centsPerHour);
        if (this.hasBaseCentsPerHour)
            writer.writeInt64(2, this.baseCentsPerHour);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetDeploymentPriceRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetDeploymentPriceRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.centsPerHour = reader.readInt64();
                    break;
                case 2:
                    message.baseCentsPerHour = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetDeploymentPriceRes {
        return DeploymentCtlProtoGetDeploymentPriceRes.deserialize(bytes);
    }
}
export class DeploymentCtlProtoCreateDeployment extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoCreateDeployment {
        const message = new DeploymentCtlProtoCreateDeployment({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoCreateDeployment {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoCreateDeployment();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoCreateDeployment {
        return DeploymentCtlProtoCreateDeployment.deserialize(bytes);
    }
}
export class DeploymentCtlProtoCreateDeploymentReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deployment?: dependency_1.DeploymentProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deployment" in data && data.deployment != undefined) {
                this.deployment = data.deployment;
            }
        }
    }
    get deployment() {
        return pb_1.Message.getWrapperField(this, dependency_1.DeploymentProto, 1) as dependency_1.DeploymentProto;
    }
    set deployment(value: dependency_1.DeploymentProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasDeployment() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        deployment?: ReturnType<typeof dependency_1.DeploymentProto.prototype.toObject>;
    }): DeploymentCtlProtoCreateDeploymentReq {
        const message = new DeploymentCtlProtoCreateDeploymentReq({});
        if (data.deployment != null) {
            message.deployment = dependency_1.DeploymentProto.fromObject(data.deployment);
        }
        return message;
    }
    toObject() {
        const data: {
            deployment?: ReturnType<typeof dependency_1.DeploymentProto.prototype.toObject>;
        } = {};
        if (this.deployment != null) {
            data.deployment = this.deployment.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasDeployment)
            writer.writeMessage(1, this.deployment, () => this.deployment.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoCreateDeploymentReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoCreateDeploymentReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.deployment, () => message.deployment = dependency_1.DeploymentProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoCreateDeploymentReq {
        return DeploymentCtlProtoCreateDeploymentReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetDeployment extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoGetDeployment {
        const message = new DeploymentCtlProtoGetDeployment({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetDeployment {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetDeployment();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetDeployment {
        return DeploymentCtlProtoGetDeployment.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetDeploymentReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuid?: Uint8Array;
        deploymentId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuid" in data && data.projectUuid != undefined) {
                this.projectUuid = data.projectUuid;
            }
            if ("deploymentId" in data && data.deploymentId != undefined) {
                this.deploymentId = data.deploymentId;
            }
        }
    }
    get projectUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set projectUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get deploymentId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set deploymentId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        projectUuid?: Uint8Array;
        deploymentId?: string;
    }): DeploymentCtlProtoGetDeploymentReq {
        const message = new DeploymentCtlProtoGetDeploymentReq({});
        if (data.projectUuid != null) {
            message.projectUuid = data.projectUuid;
        }
        if (data.deploymentId != null) {
            message.deploymentId = data.deploymentId;
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuid?: Uint8Array;
            deploymentId?: string;
        } = {};
        if (this.projectUuid != null) {
            data.projectUuid = this.projectUuid;
        }
        if (this.deploymentId != null) {
            data.deploymentId = this.deploymentId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuid.length)
            writer.writeBytes(1, this.projectUuid);
        if (this.deploymentId.length)
            writer.writeString(2, this.deploymentId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetDeploymentReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetDeploymentReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.projectUuid = reader.readBytes();
                    break;
                case 2:
                    message.deploymentId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetDeploymentReq {
        return DeploymentCtlProtoGetDeploymentReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetDeploymentRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deployment?: dependency_1.DeploymentProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deployment" in data && data.deployment != undefined) {
                this.deployment = data.deployment;
            }
        }
    }
    get deployment() {
        return pb_1.Message.getWrapperField(this, dependency_1.DeploymentProto, 1) as dependency_1.DeploymentProto;
    }
    set deployment(value: dependency_1.DeploymentProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasDeployment() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        deployment?: ReturnType<typeof dependency_1.DeploymentProto.prototype.toObject>;
    }): DeploymentCtlProtoGetDeploymentRes {
        const message = new DeploymentCtlProtoGetDeploymentRes({});
        if (data.deployment != null) {
            message.deployment = dependency_1.DeploymentProto.fromObject(data.deployment);
        }
        return message;
    }
    toObject() {
        const data: {
            deployment?: ReturnType<typeof dependency_1.DeploymentProto.prototype.toObject>;
        } = {};
        if (this.deployment != null) {
            data.deployment = this.deployment.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasDeployment)
            writer.writeMessage(1, this.deployment, () => this.deployment.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetDeploymentRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetDeploymentRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.deployment, () => message.deployment = dependency_1.DeploymentProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetDeploymentRes {
        return DeploymentCtlProtoGetDeploymentRes.deserialize(bytes);
    }
}
export class DeploymentCtlProtoUpdateDeployment extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoUpdateDeployment {
        const message = new DeploymentCtlProtoUpdateDeployment({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoUpdateDeployment {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoUpdateDeployment();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoUpdateDeployment {
        return DeploymentCtlProtoUpdateDeployment.deserialize(bytes);
    }
}
export class DeploymentCtlProtoUpdateDeploymentReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deployment?: dependency_1.DeploymentProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deployment" in data && data.deployment != undefined) {
                this.deployment = data.deployment;
            }
        }
    }
    get deployment() {
        return pb_1.Message.getWrapperField(this, dependency_1.DeploymentProto, 1) as dependency_1.DeploymentProto;
    }
    set deployment(value: dependency_1.DeploymentProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasDeployment() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        deployment?: ReturnType<typeof dependency_1.DeploymentProto.prototype.toObject>;
    }): DeploymentCtlProtoUpdateDeploymentReq {
        const message = new DeploymentCtlProtoUpdateDeploymentReq({});
        if (data.deployment != null) {
            message.deployment = dependency_1.DeploymentProto.fromObject(data.deployment);
        }
        return message;
    }
    toObject() {
        const data: {
            deployment?: ReturnType<typeof dependency_1.DeploymentProto.prototype.toObject>;
        } = {};
        if (this.deployment != null) {
            data.deployment = this.deployment.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasDeployment)
            writer.writeMessage(1, this.deployment, () => this.deployment.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoUpdateDeploymentReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoUpdateDeploymentReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.deployment, () => message.deployment = dependency_1.DeploymentProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoUpdateDeploymentReq {
        return DeploymentCtlProtoUpdateDeploymentReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoUpdateToLatestTypeDB extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoUpdateToLatestTypeDB {
        const message = new DeploymentCtlProtoUpdateToLatestTypeDB({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoUpdateToLatestTypeDB {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoUpdateToLatestTypeDB();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoUpdateToLatestTypeDB {
        return DeploymentCtlProtoUpdateToLatestTypeDB.deserialize(bytes);
    }
}
export class DeploymentCtlProtoUpdateToLatestTypeDBReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deploymentUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deploymentUuid" in data && data.deploymentUuid != undefined) {
                this.deploymentUuid = data.deploymentUuid;
            }
        }
    }
    get deploymentUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set deploymentUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        deploymentUuid?: Uint8Array;
    }): DeploymentCtlProtoUpdateToLatestTypeDBReq {
        const message = new DeploymentCtlProtoUpdateToLatestTypeDBReq({});
        if (data.deploymentUuid != null) {
            message.deploymentUuid = data.deploymentUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            deploymentUuid?: Uint8Array;
        } = {};
        if (this.deploymentUuid != null) {
            data.deploymentUuid = this.deploymentUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deploymentUuid.length)
            writer.writeBytes(1, this.deploymentUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoUpdateToLatestTypeDBReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoUpdateToLatestTypeDBReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.deploymentUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoUpdateToLatestTypeDBReq {
        return DeploymentCtlProtoUpdateToLatestTypeDBReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoSuspendDeployment extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoSuspendDeployment {
        const message = new DeploymentCtlProtoSuspendDeployment({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoSuspendDeployment {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoSuspendDeployment();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoSuspendDeployment {
        return DeploymentCtlProtoSuspendDeployment.deserialize(bytes);
    }
}
export class DeploymentCtlProtoSuspendDeploymentReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deploymentUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deploymentUuid" in data && data.deploymentUuid != undefined) {
                this.deploymentUuid = data.deploymentUuid;
            }
        }
    }
    get deploymentUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set deploymentUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        deploymentUuid?: Uint8Array;
    }): DeploymentCtlProtoSuspendDeploymentReq {
        const message = new DeploymentCtlProtoSuspendDeploymentReq({});
        if (data.deploymentUuid != null) {
            message.deploymentUuid = data.deploymentUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            deploymentUuid?: Uint8Array;
        } = {};
        if (this.deploymentUuid != null) {
            data.deploymentUuid = this.deploymentUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deploymentUuid.length)
            writer.writeBytes(1, this.deploymentUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoSuspendDeploymentReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoSuspendDeploymentReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.deploymentUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoSuspendDeploymentReq {
        return DeploymentCtlProtoSuspendDeploymentReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoResumeDeployment extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoResumeDeployment {
        const message = new DeploymentCtlProtoResumeDeployment({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoResumeDeployment {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoResumeDeployment();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoResumeDeployment {
        return DeploymentCtlProtoResumeDeployment.deserialize(bytes);
    }
}
export class DeploymentCtlProtoResumeDeploymentReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deploymentUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deploymentUuid" in data && data.deploymentUuid != undefined) {
                this.deploymentUuid = data.deploymentUuid;
            }
        }
    }
    get deploymentUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set deploymentUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        deploymentUuid?: Uint8Array;
    }): DeploymentCtlProtoResumeDeploymentReq {
        const message = new DeploymentCtlProtoResumeDeploymentReq({});
        if (data.deploymentUuid != null) {
            message.deploymentUuid = data.deploymentUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            deploymentUuid?: Uint8Array;
        } = {};
        if (this.deploymentUuid != null) {
            data.deploymentUuid = this.deploymentUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deploymentUuid.length)
            writer.writeBytes(1, this.deploymentUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoResumeDeploymentReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoResumeDeploymentReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.deploymentUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoResumeDeploymentReq {
        return DeploymentCtlProtoResumeDeploymentReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoDestroyDeployment extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoDestroyDeployment {
        const message = new DeploymentCtlProtoDestroyDeployment({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoDestroyDeployment {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoDestroyDeployment();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoDestroyDeployment {
        return DeploymentCtlProtoDestroyDeployment.deserialize(bytes);
    }
}
export class DeploymentCtlProtoDestroyDeploymentReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deploymentUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deploymentUuid" in data && data.deploymentUuid != undefined) {
                this.deploymentUuid = data.deploymentUuid;
            }
        }
    }
    get deploymentUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set deploymentUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        deploymentUuid?: Uint8Array;
    }): DeploymentCtlProtoDestroyDeploymentReq {
        const message = new DeploymentCtlProtoDestroyDeploymentReq({});
        if (data.deploymentUuid != null) {
            message.deploymentUuid = data.deploymentUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            deploymentUuid?: Uint8Array;
        } = {};
        if (this.deploymentUuid != null) {
            data.deploymentUuid = this.deploymentUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deploymentUuid.length)
            writer.writeBytes(1, this.deploymentUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoDestroyDeploymentReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoDestroyDeploymentReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.deploymentUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoDestroyDeploymentReq {
        return DeploymentCtlProtoDestroyDeploymentReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoListServers extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoListServers {
        const message = new DeploymentCtlProtoListServers({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoListServers {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoListServers();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoListServers {
        return DeploymentCtlProtoListServers.deserialize(bytes);
    }
}
export class DeploymentCtlProtoListServersReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deploymentUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deploymentUuid" in data && data.deploymentUuid != undefined) {
                this.deploymentUuid = data.deploymentUuid;
            }
        }
    }
    get deploymentUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set deploymentUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        deploymentUuid?: Uint8Array;
    }): DeploymentCtlProtoListServersReq {
        const message = new DeploymentCtlProtoListServersReq({});
        if (data.deploymentUuid != null) {
            message.deploymentUuid = data.deploymentUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            deploymentUuid?: Uint8Array;
        } = {};
        if (this.deploymentUuid != null) {
            data.deploymentUuid = this.deploymentUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deploymentUuid.length)
            writer.writeBytes(1, this.deploymentUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoListServersReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoListServersReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.deploymentUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoListServersReq {
        return DeploymentCtlProtoListServersReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoListServersRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        servers?: dependency_1.DeploymentProtoServer[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("servers" in data && data.servers != undefined) {
                this.servers = data.servers;
            }
        }
    }
    get servers() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.DeploymentProtoServer, 1) as dependency_1.DeploymentProtoServer[];
    }
    set servers(value: dependency_1.DeploymentProtoServer[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        servers?: ReturnType<typeof dependency_1.DeploymentProtoServer.prototype.toObject>[];
    }): DeploymentCtlProtoListServersRes {
        const message = new DeploymentCtlProtoListServersRes({});
        if (data.servers != null) {
            message.servers = data.servers.map(item => dependency_1.DeploymentProtoServer.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            servers?: ReturnType<typeof dependency_1.DeploymentProtoServer.prototype.toObject>[];
        } = {};
        if (this.servers != null) {
            data.servers = this.servers.map((item: dependency_1.DeploymentProtoServer) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.servers.length)
            writer.writeRepeatedMessage(1, this.servers, (item: dependency_1.DeploymentProtoServer) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoListServersRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoListServersRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.servers, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.DeploymentProtoServer.deserialize(reader), dependency_1.DeploymentProtoServer));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoListServersRes {
        return DeploymentCtlProtoListServersRes.deserialize(bytes);
    }
}
export class DeploymentCtlProtoListServerLogs extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoListServerLogs {
        const message = new DeploymentCtlProtoListServerLogs({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoListServerLogs {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoListServerLogs();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoListServerLogs {
        return DeploymentCtlProtoListServerLogs.deserialize(bytes);
    }
}
export class DeploymentCtlProtoListServerLogsReq extends pb_1.Message {
    #one_of_decls: number[][] = [[4]];
    constructor(data?: any[] | ({
        deploymentUuid?: Uint8Array;
        serverUuid?: Uint8Array;
        serverIndex?: number;
        snapshot?: boolean;
    } & (({
        initialLineCount?: number;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deploymentUuid" in data && data.deploymentUuid != undefined) {
                this.deploymentUuid = data.deploymentUuid;
            }
            if ("serverUuid" in data && data.serverUuid != undefined) {
                this.serverUuid = data.serverUuid;
            }
            if ("serverIndex" in data && data.serverIndex != undefined) {
                this.serverIndex = data.serverIndex;
            }
            if ("initialLineCount" in data && data.initialLineCount != undefined) {
                this.initialLineCount = data.initialLineCount;
            }
            if ("snapshot" in data && data.snapshot != undefined) {
                this.snapshot = data.snapshot;
            }
        }
    }
    get deploymentUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set deploymentUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get serverUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set serverUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get serverIndex() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set serverIndex(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get initialLineCount() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set initialLineCount(value: number) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0], value);
    }
    get hasInitialLineCount() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get snapshot() {
        return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
    }
    set snapshot(value: boolean) {
        pb_1.Message.setField(this, 5, value);
    }
    get _initial_line_count() {
        const cases: {
            [index: number]: "none" | "initialLineCount";
        } = {
            0: "none",
            4: "initialLineCount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4])];
    }
    static fromObject(data: {
        deploymentUuid?: Uint8Array;
        serverUuid?: Uint8Array;
        serverIndex?: number;
        initialLineCount?: number;
        snapshot?: boolean;
    }): DeploymentCtlProtoListServerLogsReq {
        const message = new DeploymentCtlProtoListServerLogsReq({});
        if (data.deploymentUuid != null) {
            message.deploymentUuid = data.deploymentUuid;
        }
        if (data.serverUuid != null) {
            message.serverUuid = data.serverUuid;
        }
        if (data.serverIndex != null) {
            message.serverIndex = data.serverIndex;
        }
        if (data.initialLineCount != null) {
            message.initialLineCount = data.initialLineCount;
        }
        if (data.snapshot != null) {
            message.snapshot = data.snapshot;
        }
        return message;
    }
    toObject() {
        const data: {
            deploymentUuid?: Uint8Array;
            serverUuid?: Uint8Array;
            serverIndex?: number;
            initialLineCount?: number;
            snapshot?: boolean;
        } = {};
        if (this.deploymentUuid != null) {
            data.deploymentUuid = this.deploymentUuid;
        }
        if (this.serverUuid != null) {
            data.serverUuid = this.serverUuid;
        }
        if (this.serverIndex != null) {
            data.serverIndex = this.serverIndex;
        }
        if (this.initialLineCount != null) {
            data.initialLineCount = this.initialLineCount;
        }
        if (this.snapshot != null) {
            data.snapshot = this.snapshot;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deploymentUuid.length)
            writer.writeBytes(1, this.deploymentUuid);
        if (this.serverUuid.length)
            writer.writeBytes(2, this.serverUuid);
        if (this.serverIndex != 0)
            writer.writeInt32(3, this.serverIndex);
        if (this.hasInitialLineCount)
            writer.writeInt32(4, this.initialLineCount);
        if (this.snapshot != false)
            writer.writeBool(5, this.snapshot);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoListServerLogsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoListServerLogsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.deploymentUuid = reader.readBytes();
                    break;
                case 2:
                    message.serverUuid = reader.readBytes();
                    break;
                case 3:
                    message.serverIndex = reader.readInt32();
                    break;
                case 4:
                    message.initialLineCount = reader.readInt32();
                    break;
                case 5:
                    message.snapshot = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoListServerLogsReq {
        return DeploymentCtlProtoListServerLogsReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoListServerLogsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        logBatch?: dependency_1.DeploymentProtoServerLogBatch;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("logBatch" in data && data.logBatch != undefined) {
                this.logBatch = data.logBatch;
            }
        }
    }
    get logBatch() {
        return pb_1.Message.getWrapperField(this, dependency_1.DeploymentProtoServerLogBatch, 1) as dependency_1.DeploymentProtoServerLogBatch;
    }
    set logBatch(value: dependency_1.DeploymentProtoServerLogBatch) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasLogBatch() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        logBatch?: ReturnType<typeof dependency_1.DeploymentProtoServerLogBatch.prototype.toObject>;
    }): DeploymentCtlProtoListServerLogsRes {
        const message = new DeploymentCtlProtoListServerLogsRes({});
        if (data.logBatch != null) {
            message.logBatch = dependency_1.DeploymentProtoServerLogBatch.fromObject(data.logBatch);
        }
        return message;
    }
    toObject() {
        const data: {
            logBatch?: ReturnType<typeof dependency_1.DeploymentProtoServerLogBatch.prototype.toObject>;
        } = {};
        if (this.logBatch != null) {
            data.logBatch = this.logBatch.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasLogBatch)
            writer.writeMessage(1, this.logBatch, () => this.logBatch.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoListServerLogsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoListServerLogsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.logBatch, () => message.logBatch = dependency_1.DeploymentProtoServerLogBatch.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoListServerLogsRes {
        return DeploymentCtlProtoListServerLogsRes.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetLatestTypeDB extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoGetLatestTypeDB {
        const message = new DeploymentCtlProtoGetLatestTypeDB({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetLatestTypeDB {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetLatestTypeDB();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetLatestTypeDB {
        return DeploymentCtlProtoGetLatestTypeDB.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetLatestTypeDBReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoGetLatestTypeDBReq {
        const message = new DeploymentCtlProtoGetLatestTypeDBReq({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetLatestTypeDBReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetLatestTypeDBReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetLatestTypeDBReq {
        return DeploymentCtlProtoGetLatestTypeDBReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoGetLatestTypeDBRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        typedbVersion?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("typedbVersion" in data && data.typedbVersion != undefined) {
                this.typedbVersion = data.typedbVersion;
            }
        }
    }
    get typedbVersion() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set typedbVersion(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        typedbVersion?: string;
    }): DeploymentCtlProtoGetLatestTypeDBRes {
        const message = new DeploymentCtlProtoGetLatestTypeDBRes({});
        if (data.typedbVersion != null) {
            message.typedbVersion = data.typedbVersion;
        }
        return message;
    }
    toObject() {
        const data: {
            typedbVersion?: string;
        } = {};
        if (this.typedbVersion != null) {
            data.typedbVersion = this.typedbVersion;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.typedbVersion.length)
            writer.writeString(1, this.typedbVersion);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoGetLatestTypeDBRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoGetLatestTypeDBRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.typedbVersion = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoGetLatestTypeDBRes {
        return DeploymentCtlProtoGetLatestTypeDBRes.deserialize(bytes);
    }
}
export class DeploymentCtlProtoInitAdminUser extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoInitAdminUser {
        const message = new DeploymentCtlProtoInitAdminUser({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoInitAdminUser {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoInitAdminUser();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoInitAdminUser {
        return DeploymentCtlProtoInitAdminUser.deserialize(bytes);
    }
}
export class DeploymentCtlProtoInitAdminUserReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deploymentUuid?: Uint8Array;
        password?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deploymentUuid" in data && data.deploymentUuid != undefined) {
                this.deploymentUuid = data.deploymentUuid;
            }
            if ("password" in data && data.password != undefined) {
                this.password = data.password;
            }
        }
    }
    get deploymentUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set deploymentUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get password() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set password(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        deploymentUuid?: Uint8Array;
        password?: string;
    }): DeploymentCtlProtoInitAdminUserReq {
        const message = new DeploymentCtlProtoInitAdminUserReq({});
        if (data.deploymentUuid != null) {
            message.deploymentUuid = data.deploymentUuid;
        }
        if (data.password != null) {
            message.password = data.password;
        }
        return message;
    }
    toObject() {
        const data: {
            deploymentUuid?: Uint8Array;
            password?: string;
        } = {};
        if (this.deploymentUuid != null) {
            data.deploymentUuid = this.deploymentUuid;
        }
        if (this.password != null) {
            data.password = this.password;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deploymentUuid.length)
            writer.writeBytes(1, this.deploymentUuid);
        if (this.password.length)
            writer.writeString(2, this.password);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoInitAdminUserReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoInitAdminUserReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.deploymentUuid = reader.readBytes();
                    break;
                case 2:
                    message.password = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoInitAdminUserReq {
        return DeploymentCtlProtoInitAdminUserReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoInitAdminUserRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoInitAdminUserRes {
        const message = new DeploymentCtlProtoInitAdminUserRes({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoInitAdminUserRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoInitAdminUserRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoInitAdminUserRes {
        return DeploymentCtlProtoInitAdminUserRes.deserialize(bytes);
    }
}
export class DeploymentCtlProtoCreateDatabase extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoCreateDatabase {
        const message = new DeploymentCtlProtoCreateDatabase({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoCreateDatabase {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoCreateDatabase();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoCreateDatabase {
        return DeploymentCtlProtoCreateDatabase.deserialize(bytes);
    }
}
export class DeploymentCtlProtoCreateDatabaseReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deploymentUuid?: Uint8Array;
        username?: string;
        password?: string;
        name?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deploymentUuid" in data && data.deploymentUuid != undefined) {
                this.deploymentUuid = data.deploymentUuid;
            }
            if ("username" in data && data.username != undefined) {
                this.username = data.username;
            }
            if ("password" in data && data.password != undefined) {
                this.password = data.password;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
        }
    }
    get deploymentUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set deploymentUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get username() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set username(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get password() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set password(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data: {
        deploymentUuid?: Uint8Array;
        username?: string;
        password?: string;
        name?: string;
    }): DeploymentCtlProtoCreateDatabaseReq {
        const message = new DeploymentCtlProtoCreateDatabaseReq({});
        if (data.deploymentUuid != null) {
            message.deploymentUuid = data.deploymentUuid;
        }
        if (data.username != null) {
            message.username = data.username;
        }
        if (data.password != null) {
            message.password = data.password;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        return message;
    }
    toObject() {
        const data: {
            deploymentUuid?: Uint8Array;
            username?: string;
            password?: string;
            name?: string;
        } = {};
        if (this.deploymentUuid != null) {
            data.deploymentUuid = this.deploymentUuid;
        }
        if (this.username != null) {
            data.username = this.username;
        }
        if (this.password != null) {
            data.password = this.password;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deploymentUuid.length)
            writer.writeBytes(1, this.deploymentUuid);
        if (this.username.length)
            writer.writeString(2, this.username);
        if (this.password.length)
            writer.writeString(3, this.password);
        if (this.name.length)
            writer.writeString(4, this.name);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoCreateDatabaseReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoCreateDatabaseReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.deploymentUuid = reader.readBytes();
                    break;
                case 2:
                    message.username = reader.readString();
                    break;
                case 3:
                    message.password = reader.readString();
                    break;
                case 4:
                    message.name = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoCreateDatabaseReq {
        return DeploymentCtlProtoCreateDatabaseReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoCreateDatabaseRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoCreateDatabaseRes {
        const message = new DeploymentCtlProtoCreateDatabaseRes({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoCreateDatabaseRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoCreateDatabaseRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoCreateDatabaseRes {
        return DeploymentCtlProtoCreateDatabaseRes.deserialize(bytes);
    }
}
export class DeploymentCtlProtoListSampleDatasets extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoListSampleDatasets {
        const message = new DeploymentCtlProtoListSampleDatasets({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoListSampleDatasets {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoListSampleDatasets();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoListSampleDatasets {
        return DeploymentCtlProtoListSampleDatasets.deserialize(bytes);
    }
}
export class DeploymentCtlProtoListSampleDatasetsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        typedbVersion?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("typedbVersion" in data && data.typedbVersion != undefined) {
                this.typedbVersion = data.typedbVersion;
            }
        }
    }
    get typedbVersion() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set typedbVersion(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        typedbVersion?: string;
    }): DeploymentCtlProtoListSampleDatasetsReq {
        const message = new DeploymentCtlProtoListSampleDatasetsReq({});
        if (data.typedbVersion != null) {
            message.typedbVersion = data.typedbVersion;
        }
        return message;
    }
    toObject() {
        const data: {
            typedbVersion?: string;
        } = {};
        if (this.typedbVersion != null) {
            data.typedbVersion = this.typedbVersion;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.typedbVersion.length)
            writer.writeString(1, this.typedbVersion);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoListSampleDatasetsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoListSampleDatasetsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.typedbVersion = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoListSampleDatasetsReq {
        return DeploymentCtlProtoListSampleDatasetsReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoListSampleDatasetsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        name?: string[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
        }
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
    }
    set name(value: string[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        name?: string[];
    }): DeploymentCtlProtoListSampleDatasetsRes {
        const message = new DeploymentCtlProtoListSampleDatasetsRes({});
        if (data.name != null) {
            message.name = data.name;
        }
        return message;
    }
    toObject() {
        const data: {
            name?: string[];
        } = {};
        if (this.name != null) {
            data.name = this.name;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.name.length)
            writer.writeRepeatedString(1, this.name);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoListSampleDatasetsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoListSampleDatasetsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoListSampleDatasetsRes {
        return DeploymentCtlProtoListSampleDatasetsRes.deserialize(bytes);
    }
}
export class DeploymentCtlProtoLoadSampleDataset extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoLoadSampleDataset {
        const message = new DeploymentCtlProtoLoadSampleDataset({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoLoadSampleDataset {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoLoadSampleDataset();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoLoadSampleDataset {
        return DeploymentCtlProtoLoadSampleDataset.deserialize(bytes);
    }
}
export class DeploymentCtlProtoLoadSampleDatasetReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        deploymentUuid?: Uint8Array;
        username?: string;
        password?: string;
        database?: string;
        name?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deploymentUuid" in data && data.deploymentUuid != undefined) {
                this.deploymentUuid = data.deploymentUuid;
            }
            if ("username" in data && data.username != undefined) {
                this.username = data.username;
            }
            if ("password" in data && data.password != undefined) {
                this.password = data.password;
            }
            if ("database" in data && data.database != undefined) {
                this.database = data.database;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
        }
    }
    get deploymentUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set deploymentUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get username() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set username(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get password() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set password(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get database() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set database(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        deploymentUuid?: Uint8Array;
        username?: string;
        password?: string;
        database?: string;
        name?: string;
    }): DeploymentCtlProtoLoadSampleDatasetReq {
        const message = new DeploymentCtlProtoLoadSampleDatasetReq({});
        if (data.deploymentUuid != null) {
            message.deploymentUuid = data.deploymentUuid;
        }
        if (data.username != null) {
            message.username = data.username;
        }
        if (data.password != null) {
            message.password = data.password;
        }
        if (data.database != null) {
            message.database = data.database;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        return message;
    }
    toObject() {
        const data: {
            deploymentUuid?: Uint8Array;
            username?: string;
            password?: string;
            database?: string;
            name?: string;
        } = {};
        if (this.deploymentUuid != null) {
            data.deploymentUuid = this.deploymentUuid;
        }
        if (this.username != null) {
            data.username = this.username;
        }
        if (this.password != null) {
            data.password = this.password;
        }
        if (this.database != null) {
            data.database = this.database;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.deploymentUuid.length)
            writer.writeBytes(1, this.deploymentUuid);
        if (this.username.length)
            writer.writeString(2, this.username);
        if (this.password.length)
            writer.writeString(3, this.password);
        if (this.database.length)
            writer.writeString(4, this.database);
        if (this.name.length)
            writer.writeString(5, this.name);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoLoadSampleDatasetReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoLoadSampleDatasetReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.deploymentUuid = reader.readBytes();
                    break;
                case 2:
                    message.username = reader.readString();
                    break;
                case 3:
                    message.password = reader.readString();
                    break;
                case 4:
                    message.database = reader.readString();
                    break;
                case 5:
                    message.name = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoLoadSampleDatasetReq {
        return DeploymentCtlProtoLoadSampleDatasetReq.deserialize(bytes);
    }
}
export class DeploymentCtlProtoLoadSampleDatasetRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): DeploymentCtlProtoLoadSampleDatasetRes {
        const message = new DeploymentCtlProtoLoadSampleDatasetRes({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeploymentCtlProtoLoadSampleDatasetRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeploymentCtlProtoLoadSampleDatasetRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DeploymentCtlProtoLoadSampleDatasetRes {
        return DeploymentCtlProtoLoadSampleDatasetRes.deserialize(bytes);
    }
}
